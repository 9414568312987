<template>
  <section
    class="template-dashboard-view edit-new-template-view"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      v-loading.fullscreen.lock="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :fullscreen="true"
    >
      <div class="templates-title-block" v-loading="loading">
        <div class="templates-navbar">
          <!-- Template Build Modal -->
          <div class="top-bar">
            <div class="left-side">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 5px;
                "
              >
                <el-button
                  @click="backToTemplates"
                  slot="reference"
                  class="rounded-0 back-btn type-2"
                  style="height: 38px; margin-left: -5px; margin-right: 5px"
                >
                  <i class="el-icon-back">Back</i>
                </el-button>
              </div>

              <div class="action-wrapper-type-two">
                <el-button
                  slot="reference"
                  class="rounded-0 btn-one type-2"
                  type="primary"
                  v-on:click="openRightSideBar()"
                  :disabled="getIsRSOpen"
                  v-if="!getIsRSOpen && getIsMobile"
                  >&gt;&gt;</el-button
                >
                <el-button
                  slot="reference"
                  class="rounded-0 btn-one close type-2"
                  type="primary"
                  v-on:click="closeRightSideBar()"
                  v-if="getIsRSOpen && getIsMobile"
                  >&lt;&lt;</el-button
                >
              </div>
              <icons :iconName="templateIconPath"></icons>
              <h4
                style="margin-left: 15px"
                class="font-weight-normal blue-dark"
              >
                {{ name }}
              </h4>
            </div>
            <div class="right-side">
              <ul class="top-bar-list">
                <li class="list-item">
                  <el-tooltip class="el-tooltips" content="Edit Properties">
                    <a @click="dialogTemplatePrimaryData = true">
                      <el-button circle><i class="el-icon-edit"></i></el-button>
                    </a>
                  </el-tooltip>
                </li>
                <li class="list-item">
                  <el-switch
                    v-model="free_style"
                    active-text="Free style"
                    inactive-text="Form Style"
                    @change="handleFormStyleChange"
                  >
                  </el-switch>
                </li>
                <li class="list-item ml-1 mr-1">
                  <el-tooltip class="el-tooltips" content="Save Template">
                    <el-button
                      type="primary"
                      @click="saveTemplate"
                      :disabled="submitDisable()"
                    >
                      Save
                    </el-button>
                  </el-tooltip>
                </li>
                <el-divider
                  direction="vertical"
                  class="testLine"
                  style="margin-right: 45px"
                ></el-divider>
                <li>
                  <el-dropdown class="ml-1 mr-1">
                    <div
                      style="margin-right: 48px"
                      class="el-dropdown-link vertical-three-dots"
                    >
                      <img
                        class="arrows"
                        style="margin-bottom: -9px"
                        src="@/assets/Icon-ellipsis-v.svg"
                      />
                    </div>
                    <el-dropdown-menu>
                      <a class="list-item" @click="openDuplicateRulesPopup">
                        <el-dropdown-item>
                          <i class="el-icon-s-operation"></i>
                          Duplicate rules
                        </el-dropdown-item>
                      </a>
                      <a class="list-item" @click="openRulesPopup">
                        <el-dropdown-item>
                          <i class="el-icon-s-operation"></i>
                          Form rules
                        </el-dropdown-item>
                      </a>

                      <el-dropdown-item>
                        <a @click="previewTemplate" circle>
                          <i class="el-icon-view"></i>
                          Preview Template
                        </a>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <a @click="openTemplateStylings" circle>
                          <i class="el-icon-setting"></i>
                          Template Settings
                        </a>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>

              <!-- <span class="sm-80 grey-light-1">{{ description }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="left-side-bar">
        <div class="scrollable-container">
          <!-- <el-scrollbar class="sidemenu"> -->
          <el-collapse
            accordion
            v-model="entityFieldSets"
            class="entityFieldSets"
          >
            <el-collapse-item name="1">
              <template slot="title">
                <div class="fields-title">Basic Fields</div>
              </template>
              <div>
                <!-- <el-scrollbar class="each-field-set"> -->
                <div class="sidemenu">
                  <div
                    class="fieldSet__box"
                    v-for="(action, fIndex) of fieldActions3"
                    :key="fIndex"
                  >
                    <div>
                      <draggable
                        class="dragArea-field-element"
                        @end="
                          handleActionClick(action.inputType, action.is_fixed)
                        "
                        @mouseup="mouseUp()"
                      >
                        <el-button
                          class="field-element"
                          @mousedown="mouseDown()"
                          v-if="
                            action.img_url != '' && action.img_url != 'null'
                          "
                        >
                          <img
                            :src="'/assets/img/fields/' + action.img_url"
                            alt="icon"
                            class="icon"
                          />
                          <img
                            :src="'/assets/img/fields/hover/' + action.img_url"
                            alt="icon"
                            class="icon-hover"
                          />
                          <span class="name">{{ action.name }}</span>
                        </el-button>
                      </draggable>
                    </div>
                  </div>
                </div>
                <!-- </el-scrollbar> -->
              </div>
            </el-collapse-item>

            <el-collapse-item name="2">
              <template slot="title">
                <div class="fields-title">Content Fields</div>
              </template>
              <div>
                <!-- <el-scrollbar class="each-field-set"> -->
                <div class="content-fields-container">
                  <div
                    class="fieldSet__box"
                    v-for="(action, index) of fieldActions2"
                    :key="index"
                  >
                    <draggable
                      class="dragArea-field-element"
                      @end="handleActionClick(action.inputType)"
                      @mouseup="mouseUp()"
                    >
                      <el-button
                        class="field-element"
                        @mousedown="mouseDown()"
                        v-if="action.img_url != '' && action.img_url != 'null'"
                      >
                        <img
                          :src="'/assets/img/fields/' + action.img_url"
                          alt="icon"
                          class="icon"
                        />
                        <img
                          :src="'/assets/img/fields/hover/' + action.img_url"
                          alt="icon"
                          class="icon-hover"
                        />

                        <span class="name">{{ action.name }}</span>
                      </el-button>
                    </draggable>
                  </div>
                </div>
                <!-- </el-scrollbar> -->
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <div class="fields-title">Advanced Fields</div>
              </template>
              <div>
                <!-- <el-scrollbar class="each-field-set"> -->
                <div class="advanced-fields-container">
                  <div
                    class="fieldSet__box"
                    v-for="(action, fIndex) of advancedFieldActions"
                    :key="fIndex"
                  >
                    <draggable
                      class="dragArea-field-element"
                      @end="handleActionClick(action.inputType)"
                      @mouseup="mouseUp()"
                    >
                      <el-button
                        class="field-element"
                        @mousedown="mouseDown()"
                        v-if="action.img_url != '' && action.img_url != 'null'"
                      >
                        <img
                          :src="'/assets/img/fields/' + action.img_url"
                          alt="icon"
                          class="icon"
                        />
                        <img
                          :src="'/assets/img/fields/hover/' + action.img_url"
                          alt="icon"
                          class="icon-hover"
                        />
                        <span class="name">{{ action.name }}</span>
                      </el-button>
                    </draggable>
                  </div>
                </div>
                <!-- </el-scrollbar> -->
              </div>
            </el-collapse-item>
            <el-collapse-item name="4">
              <template slot="title">
                <div class="fields-title">Page Builders</div>
              </template>
              <div>
                <!-- <el-scrollbar class="each-field-set"> -->
                <div class="advanced-fields-container">
                  <div
                    class="fieldSet__box"
                    v-for="(action, fIndex) of utilityComponents"
                    :key="fIndex"
                  >
                    <draggable
                      class="dragArea-field-element"
                      @end="handleActionClick(action.inputType)"
                      @mouseup="mouseUp()"
                    >
                      <el-button
                        class="field-element"
                        @mousedown="mouseDown()"
                        v-if="action.img_url != '' && action.img_url != 'null'"
                      >
                        <img
                          :src="'/assets/img/fields/' + action.img_url"
                          alt="icon"
                          class="icon"
                        />
                        <img
                          :src="'/assets/img/fields/hover/' + action.img_url"
                          alt="icon"
                          class="icon-hover"
                        />
                        <span class="name">{{ action.name }}</span>
                      </el-button>
                    </draggable>
                  </div>
                </div>
                <!-- </el-scrollbar> -->
              </div>
            </el-collapse-item>
          </el-collapse>

          <!-- </el-scrollbar> -->
        </div>
      </div>
      <div
        class="base-parent"
        @click="onFieldClick($event, field)"
        :style="computedStyle"
      >
        <!-- <el-scrollbar
          wrap-style="max-width: calc(100% - 0px);height: calc(100vh - 100px) !important;"
          style="padding: 10px"
          :style="getElementStyle()"
        > -->
        <div class="form-builder" ref="formBuilder">
          <div
            :class="free_style ? 'form-parent' : 'form-parent-no-grid'"
            v-if="!templateInteractiveQuestions"
            :style="getStylediv"
          >
            <div
              id="wrapper"
              v-if="fieldsData && fieldsData.length"
              class="form-fields-holder"
              :style="getFormContainerSize"
            >
              <VueDraggableResizable
                :grid="[5, 5]"
                :class-name-active="
                  free_style ? 'dragdrop-item-active' : 'form-style-active'
                "
                class-name-handle="dragdrop-holder"
                class="dragdrop-item"
                v-for="(field, index) of fieldsData"
                :key="index"
                :class="{ 'selected-field': isSelected(field) }"
                id="each-field"
                @dragging="(x, y) => onDrag(x, y, field)"
                @resizing="
                  (x, y, width, height) => onResize(x, y, width, height, field)
                "
                :onDrag="chechFieldPosition"
                :parent="true"
                :h="field.height"
                :w="field.width"
                :x="free_style ? field.x : 40"
                :y="field.y"
                :resizable="free_style ? true : false"
                :axis="free_style ? 'both' : 'y'"
                @activated="onActivated(field, index)"
                @deactivated="onDeactivated(field, index)"
                @dblclick.native="editExistedField(field, index)"
                @mouseup.native="hideAllAlignLines"
                @mousedown.native="checkFieldsPosdown(field, index)"
                @keydown.native="handleVDRKeyDown"
              >
                <QRView
                  v-if="field.input_type === 'QR'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                >
                </QRView>
                <QRReaderView
                  v-if="field.input_type === 'QR_READER'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                ></QRReaderView>
                <IntegrationView
                  v-if="field.input_type === 'INTEGRATION'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                >
                </IntegrationView>
                <DateView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'DATE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></DateView>
                <DateTimeView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'DATE_TIME'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></DateTimeView>
                <CheckBoxGroupView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'CHECKBOX_GROUP'"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></CheckBoxGroupView>
                <RadioButtonGroupView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></RadioButtonGroupView>
                <DateTimeRangeView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'DATE_TIME_RANGE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></DateTimeRangeView>
                <DateRangeView
                  :is-active="field.active"
                  :data="field"
                  @copy-document="copyField"
                  v-if="field.input_type === 'DATE_RANGE'"
                  v-on:settings="() => openFieldSettings(index)"
                ></DateRangeView>
                <TimeView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'TIME'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></TimeView>
                <TimeRangeView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'TIME_RANGE'"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></TimeRangeView>
                <FileView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'FILE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></FileView>
                <MultiLineTextView
                  :is-active="field.active"
                  :data="field"
                  v-if="
                    field.input_type === 'MULTI_LINE_TEXT' ||
                    field.input_type === 'RICH_TEXT'
                  "
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></MultiLineTextView>
                <SingleLineTextView
                  :data="field"
                  v-if="
                    field.input_type === 'SINGLE_LINE_TEXT' ||
                    field.input_type === 'EMAIL' ||
                    field.input_type === 'PASSWORD' ||
                    field.input_type === 'MASKED' ||
                    field.input_type === 'HYPER_LINK'
                  "
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></SingleLineTextView>
                <location-view
                  :data="field"
                  v-if="field.input_type == 'LOCATION'"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                >
                </location-view>
                <MultiSelectView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'MULTI_SELECT'"
                  :isSelectView="isSelectView"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></MultiSelectView>
                <SelectView
                  :is-active="field.active"
                  :data="field"
                  :isSelectView="isSelectView"
                  v-if="field.input_type === 'SELECT'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></SelectView>
                <ListView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'LIST'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></ListView>
                <YesOrNoView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'YES_OR_NO'"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></YesOrNoView>
                <NumberView
                  :is-active="field.active"
                  :data="field"
                  v-if="
                    field.input_type === 'NUMBER' ||
                    field.input_type === 'ZIP_CODE'
                  "
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></NumberView>

                <PhoneCountryCodeView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                >
                </PhoneCountryCodeView>
                <CurrencyView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'CURRENCY'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></CurrencyView>
                <HeadingView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'HEADING'"
                  v-on:settings="() => openFieldSettings(index)"
                ></HeadingView>
                <CheckboxView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'CHECKBOX'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></CheckboxView>
                <RadioView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'RADIO'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></RadioView>
                <ESignatureView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'SIGNATURE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></ESignatureView>

                <GlobalVariableView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'GLOBAL_VARIABLE'"
                  v-on:settings="() => openFieldSettings(index)"
                ></GlobalVariableView>
                <EntityVariableView
                  :is-active="field.active"
                  :data="field"
                  :fieldsData="fieldsData"
                  :index="index"
                  v-if="field.input_type === 'ENTITY_VARIABLE'"
                  v-on:updateFieldProperty="updateFieldProperty"
                  v-on:settings="() => openFieldSettings(index)"
                  v-on:settingsDataTableColumn="
                    (colData) =>
                      openTableCoulmnFieldConfigSettings(index, colData)
                  "
                  v-on:configureColumn="
                    (colData) => openTableColumnSettings(index, colData)
                  "
                  @copy-document="copyField"
                ></EntityVariableView>
                <EntityView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'ENTITY'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></EntityView>
                <ParagraphView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'PARAGRAPH'"
                  v-on:settings="() => openFieldSettings(index)"
                ></ParagraphView>
                <SingleLineContentView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></SingleLineContentView>
                <AuthorizedSignatureView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></AuthorizedSignatureView>
                <ImageView
                  :data="field"
                  :is-active="field.active"
                  v-if="
                    field.input_type === 'IMAGE' || field.input_type === 'GIF'
                  "
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></ImageView>
                <VideoView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'VIDEO'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></VideoView>
                <FormulaView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'FORMULA'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></FormulaView>
                <ActionButtonView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'ACTION_BUTTON'"
                  :selfTemplateId="selectedTemplateId"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></ActionButtonView>
                <HtmlView
                  :data="field"
                  :is-active="field.active"
                  v-if="
                    field.inputType === 'HTML_CONTENT' ||
                    field.input_type === 'HTML_CONTENT'
                  "
                  v-on:settings="() => openFieldSettings(index)"
                ></HtmlView>
                <HorizontalLineView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'HORIZONTAL_LINE'"
                  v-on:settings="() => openFieldSettings(index)"
                ></HorizontalLineView>
                <CarouselView
                  v-if="field.input_type === 'CAROUSEL'"
                  :is-active="field.active"
                  :data="field"
                  v-on:settings="() => openFieldSettings(index)"
                >
                </CarouselView>
                <DIVView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'DIVISION'"
                  v-on:settings="() => openFieldSettings(index)"
                ></DIVView>
                <AggregateFunctionView
                  :data="field"
                  :fieldsData="fieldsData"
                  :is-active="field.active"
                  v-if="field.input_type == 'AGGREGATE_FUNCTION'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></AggregateFunctionView>
                <IconView
                  :data="field"
                  :fieldsData="fieldsData"
                  :is-active="field.active"
                  v-if="field.input_type == 'ICON'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></IconView>
                <CalenderView
                  :data="field"
                  :fieldsData="fieldsData"
                  :is-active="field.active"
                  v-if="field.input_type == 'CALENDER'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                >
                </CalenderView>
                <QuestionView
                  :data="field"
                  :is-active="field.active"
                  v-if="field.input_type === 'QUESTION'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></QuestionView>
                <AutoIncrementView
                  :is-active="field.active"
                  :data="field"
                  :fields-data="fieldsData"
                  v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></AutoIncrementView>
                <RandomTextView
                  :is-active="field.active"
                  :data="field"
                  :fields-data="fieldsData"
                  v-if="field.input_type === 'RANDOM_TEXT'"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                ></RandomTextView>
                <StarRatingView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'STAR_RATING'"
                  v-on:settings="() => openFieldSettings(index)"
                ></StarRatingView>

                <WeekDaysView
                  v-if="field.input_type === 'WEEKDAYS'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                >
                </WeekDaysView>

                <DataTableView
                  :is-active="field.active"
                  :data="field"
                  :fields-data="fieldsData"
                  v-if="!reRenderDataTable && field.input_type === 'DATA_TABLE'"
                  v-on:settings="() => openFieldSettings(index)"
                  v-on:settingsDataTableColumn="
                    (colData) =>
                      openTableCoulmnFieldConfigSettings(index, colData)
                  "
                  v-on:configureColumn="
                    (colData) => openTableColumnSettings(index, colData)
                  "
                ></DataTableView>
                <EntityTableView
                  :is-active="field.active"
                  :data="field"
                  :fields-data="fieldsData"
                  v-if="
                    !reRenderDataTable && field.input_type === 'ENTITY_TABLE'
                  "
                  v-on:settings="() => openFieldSettings(index)"
                  v-on:settingsDataTableColumn="
                    (colData) =>
                      openTableCoulmnFieldConfigSettings(index, colData)
                  "
                  v-on:configureColumn="
                    (colData) => openTableColumnSettings(index, colData)
                  "
                >
                </EntityTableView>
                <PaymentView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'PAY_BUTTON'"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></PaymentView>
                <PayVariableView
                  :is-active="field.active"
                  :data="field"
                  v-if="field.input_type === 'PAYMENT_VARIABLE'"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                ></PayVariableView>
                <ConcatenateView
                  v-if="field.input_type === 'CONCATENATE'"
                  :data="field"
                  :is-active="field.active"
                  @copy-document="copyField"
                  v-on:settings="() => openFieldSettings(index)"
                >
                </ConcatenateView>
                <AudioView
                  v-if="field.input_type === 'AUDIO'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                >
                </AudioView>
                <CaptchaView
                  v-if="field.input_type === 'CAPTCHA'"
                  :data="field"
                  :is-active="field.active"
                  v-on:settings="() => openFieldSettings(index)"
                  @copy-document="copyField"
                >
                </CaptchaView>
              </VueDraggableResizable>
              <svg id="svg">
                <!-- <line class="line" /> -->
                <line id="top_line" class="line" />
                <line id="middle_horizontal_line" class="line" />
                <line id="bottom_line" class="line" />
                <line id="left_line" class="line" />
                <line id="middle_vertical_line" class="line" />
                <line id="right_line" class="line" />
              </svg>
            </div>
            <div style="padding: 20px; text-align: center" v-else>
              <span
                style="
                  text-family: mulish;
                  text-align: center;
                  font-size: 20px;
                  font-weight: 400;
                  color: #cbd6e2;
                  opacity: 0.8;
                "
                >No Fields Added Till Now</span
              >
            </div>
          </div>

          <div
            v-if="templateInteractiveQuestions && !refreshFields"
            class="form-parent-no-grid"
          >
            <div class="form-fields-holder" id="wrapper">
              <template v-for="(field, index) of fieldsData">
                <VueDraggableResizable
                  :grid="[5, 5]"
                  class-name-active="dragdrop-item-active"
                  class-name-handle="dragdrop-holder"
                  class="dragdrop-item"
                  v-for="(field, index) of fieldsData"
                  :key="index"
                  :class="{ 'selected-field': isSelected(field) }"
                  id="each-field"
                  @dragging="(x, y) => onDrag(x, y, field)"
                  @resizing="
                    (x, y, width, height) =>
                      onResize(x, y, width, height, field)
                  "
                  :onDrag="chechFieldPosition"
                  :parent="true"
                  :h="field.height"
                  :w="field.width"
                  :x="free_style ? field.x : 0"
                  :y="field.y"
                  :axis="free_style ? 'both' : 'y'"
                  @activated="onActivated(field, index)"
                  @deactivated="onDeactivated(field, index)"
                  @dblclick.native="editExistedField(field, index)"
                  @mouseup.native="hideAllAlignLines"
                  @mousedown.native="checkFieldsPosdown(field, index)"
                  @keydown.native="handleVDRKeyDown"
                >
                  <span :key="index" v-if="field.type == 'FIELD'">
                    <strong>{{ getQuestionIndex(field.key) }}</strong>
                  </span>
                  <DateView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'DATE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></DateView>
                  <DateTimeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'DATE_TIME'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></DateTimeView>
                  <DateTimeRangeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'DATE_TIME_RANGE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></DateTimeRangeView>
                  <DateRangeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'DATE_RANGE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></DateRangeView>
                  <TimeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'TIME'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></TimeView>
                  <TimeRangeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'TIME_RANGE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></TimeRangeView>
                  <FileView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'FILE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></FileView>
                  <MultiLineTextView
                    :is-active="field.active"
                    :data="field"
                    v-if="
                      field.input_type === 'MULTI_LINE_TEXT' ||
                      field.input_type === 'RICH_TEXT'
                    "
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></MultiLineTextView>
                  <SingleLineTextView
                    :data="field"
                    v-if="
                      field.input_type === 'SINGLE_LINE_TEXT' ||
                      field.input_type === 'EMAIL' ||
                      field.input_type === 'PASSWORD' ||
                      field.input_type === 'MASKED' ||
                      field.input_type === 'HYPER_LINK'
                    "
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></SingleLineTextView>
                  <MultiSelectView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'MULTI_SELECT'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></MultiSelectView>
                  <SelectView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'SELECT'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></SelectView>
                  <ListView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'LIST'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></ListView>
                  <YesOrNoView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'YES_OR_NO'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></YesOrNoView>
                  <NumberView
                    :is-active="field.active"
                    :data="field"
                    v-if="
                      field.input_type === 'NUMBER' ||
                      field.input_type === 'PHONE' ||
                      field.input_type === 'ZIP_CODE'
                    "
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></NumberView>
                  <CurrencyView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'CURRENCY'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></CurrencyView>
                  <PhoneCountryCodeView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </PhoneCountryCodeView>

                  <HeadingView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'HEADING'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></HeadingView>
                  <CheckboxView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'CHECKBOX'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></CheckboxView>
                  <RadioView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'RADIO'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></RadioView>

                  <RadioButtonGroupView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </RadioButtonGroupView>

                  <CheckBoxGroupView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'CHECKBOX_GROUP'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></CheckBoxGroupView>
                  <ESignatureView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'SIGNATURE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></ESignatureView>

                  <GlobalVariableView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'GLOBAL_VARIABLE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></GlobalVariableView>
                  <EntityVariableView
                    :is-active="field.active"
                    :data="field"
                    :fieldsData="fieldsData"
                    :index="index"
                    v-if="
                      field.input_type === 'ENTITY_VARIABLE' &&
                      ((field.inputType === 'DATA_TABLE' &&
                        !reRenderDataTable) ||
                        field.inputType != 'DATA_TABLE')
                    "
                    v-on:settings="() => openFieldSettings(index)"
                    v-on:settingsDataTableColumn="
                      (colData) =>
                        openTableCoulmnFieldConfigSettings(index, colData)
                    "
                    v-on:configureColumn="
                      (colData) => openTableColumnSettings(index, colData)
                    "
                    @copy-document="copyField"
                  ></EntityVariableView>

                  <EntityView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'ENTITY'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></EntityView>

                  <ParagraphView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'PARAGRAPH'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></ParagraphView>
                  <SingleLineContentView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></SingleLineContentView>
                  <AuthorizedSignatureView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></AuthorizedSignatureView>
                  <ImageView
                    :data="field"
                    :is-active="field.active"
                    v-if="
                      field.input_type === 'IMAGE' || field.input_type === 'GIF'
                    "
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></ImageView>
                  <VideoView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'VIDEO'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></VideoView>
                  <FormulaView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'FORMULA'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></FormulaView>
                  <ActionButtonView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'ACTION_BUTTON'"
                    :selfTemplateId="selectedTemplateId"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></ActionButtonView>
                  <HtmlView
                    :data="field"
                    :is-active="field.active"
                    v-if="
                      field.inputType === 'HTML_CONTENT' ||
                      field.input_type === 'HTML_CONTENT'
                    "
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></HtmlView>
                  <HorizontalLineView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'HORIZONTAL_LINE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></HorizontalLineView>
                  <CarouselView
                    v-if="field.input_type === 'CAROUSEL'"
                    :is-active="field.active"
                    :data="field"
                    v-on:settings="() => openFieldSettings(index)"
                  >
                  </CarouselView>
                  <DIVView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'DIVISION'"
                    v-on:settings="() => openFieldSettings(index)"
                  ></DIVView>
                  <AggregateFunctionView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></AggregateFunctionView>
                  <QuestionView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'QUESTION'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></QuestionView>
                  <StarRatingView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'STAR_RATING'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></StarRatingView>
                  <WeekDaysView
                    v-if="field.input_type === 'WEEKDAYS'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </WeekDaysView>
                  <AutoIncrementView
                    :data="field"
                    :is-active="field.active"
                    v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </AutoIncrementView>
                  <RandomTextView
                    :is-active="field.active"
                    :data="field"
                    :fields-data="fieldsData"
                    v-if="field.input_type === 'RANDOM_TEXT'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></RandomTextView>
                  <location-view
                    :data="field"
                    v-if="field.input_type == 'LOCATION'"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                  >
                  </location-view>
                  <PaymentView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'PAY_BUTTON'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></PaymentView>
                  <PayablesView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'RECEIVE_BUTTON'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  />
                  <PayVariableView
                    :is-active="field.active"
                    :data="field"
                    v-if="field.input_type === 'PAYMENT_VARIABLE'"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  ></PayVariableView>
                  <ConcatenateView
                    v-if="field.input_type === 'CONCATENATE'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </ConcatenateView>
                  <AudioView
                    v-if="field.input_type === 'AUDIO'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </AudioView>
                  <CaptchaView
                    v-if="field.input_type === 'CAPTCHA'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </CaptchaView>
                  <QRView
                    v-if="field.input_type === 'QR'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </QRView>
                  <QRReaderView
                    v-if="field.input_type === 'QR_READER'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </QRReaderView>
                  <IntegrationView
                    v-if="field.input_type === 'INTEGRATION'"
                    :data="field"
                    :is-active="field.active"
                    v-on:settings="() => openFieldSettings(index)"
                    @copy-document="copyField"
                  >
                  </IntegrationView>
                </VueDraggableResizable>
                <div
                  :key="index + 'field_navigation'"
                  style="position: relative; margin: 51px 0px 0px 99px"
                >
                  <el-button
                    icon="el-icon-top"
                    size="mini"
                    @click="moveFieldsTop(index)"
                    :disabled="index == 0"
                  ></el-button>
                  <el-button
                    icon="el-icon-bottom"
                    size="mini"
                    @click="moveFieldsBottom(index)"
                    :disabled="index == fieldsData.length - 1"
                  ></el-button>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>

    <div
      class="vue-modal edit-template-modal"
      v-loading="fetchingActionButtonTemplateFields"
    >
      <dialog-component
        v-loading="fetchingActionButtonTemplateFields"
        :title="modalTitle"
        :visible="dialogFormVisible"
        @before-close="canelEditField"
        :containerMaxWidth="'80%'"
        :containerMaxHeight="'80%'"
        :containerMinWidth="'50%'"
        :containerMinHeight="'10%'"
      >
        <template>
          <SingleLineText
            v-if="
              editableField.input_type === 'SINGLE_LINE_TEXT' ||
              editableField.input_type === 'PASSWORD' ||
              editableField.input_type === 'EMAIL' ||
              editableField.input_type === 'MASKED' ||
              editableField.input_type === 'HYPER_LINK'
            "
            :field="editableField"
          />
          <!-- <AutoIncrement
            v-else-if="
              editableField.input_type === 'AUTO_INCREMENT_NUMBER'
            "
            :field="editableField"
          />
          <Address
            v-else-if="
              editableField.input_type === 'ADDRESS'
            "
            :field="editableField"
          /> -->
          <MultiLineText
            v-else-if="
              editableField.input_type === 'MULTI_LINE_TEXT' ||
              editableField.input_type === 'RICH_TEXT'
            "
            :field="editableField"
          />
          <Number
            v-else-if="
              editableField.input_type === 'NUMBER' ||
              editableField.input_type === 'FAX' ||
              editableField.input_type === 'ZIP_CODE' ||
              editableField.input_type === 'PHONE'
            "
            :field="editableField"
          />
          <PhoneCountryCode
            v-else-if="editableField.input_type === 'PHONE_COUNTRY_CODE'"
            :field="editableField"
          />
          <Currency
            v-else-if="editableField.input_type === 'CURRENCY'"
            :field="editableField"
          />
          <Date
            v-else-if="editableField.input_type === 'DATE'"
            :field="editableField"
            :fieldsData="getFieldsData"
          />
          <Time
            v-else-if="editableField.input_type === 'TIME'"
            :field="editableField"
          />
          <TimeRange
            v-else-if="editableField.input_type === 'TIME_RANGE'"
            :field="editableField"
          />
          <DateTime
            v-else-if="editableField.input_type === 'DATE_TIME'"
            :field="editableField"
          />
          <DateTimeRange
            v-else-if="editableField.input_type === 'DATE_TIME_RANGE'"
            :field="editableField"
          />
          <CheckBoxGroup
            v-else-if="editableField.input_type === 'CHECKBOX_GROUP'"
            :field="editableField"
          />
          <RadioButtonGroup
            v-else-if="editableField.input_type === 'RADIO_BUTTON_GROUP'"
            :field="editableField"
          />
          <DateRange
            v-else-if="editableField.input_type === 'DATE_RANGE'"
            :field="editableField"
          />
          <!-- <RadioButtonGroup
          v-else-if="editableField.input_type === 'RADIO_BUTTON'"
            :field="editableField"
          /> -->
          <File
            v-else-if="editableField.input_type === 'FILE'"
            :field="editableField"
            :isExecute="true"
          />
          <Select
            v-else-if="editableField.input_type === 'SELECT'"
            :field="editableField"
          />
          <List
            v-else-if="editableField.input_type === 'LIST'"
            :field="editableField"
          />
          <YesOrNo
            v-else-if="editableField.input_type === 'YES_OR_NO'"
            :field="editableField"
          />
          <Heading
            v-else-if="editableField.input_type === 'HEADING'"
            :field="editableField"
          />
          <Checkbox
            v-else-if="editableField.input_type === 'CHECKBOX'"
            :field="editableField"
          />
          <Radio
            v-else-if="editableField.input_type === 'RADIO'"
            :field="editableField"
          />
          <MultiSelect
            v-else-if="editableField.input_type === 'MULTI_SELECT'"
            :field="editableField"
          />
          <ESignature
            v-else-if="editableField.input_type === 'SIGNATURE'"
            :field="editableField"
          />
          <GlobalVariable
            v-else-if="editableField.input_type === 'GLOBAL_VARIABLE'"
            :field="editableField"
          />
          <EntityVariable
            v-else-if="editableField.input_type === 'ENTITY_VARIABLE'"
            :field="editableField"
            :fieldsData="getFieldsData"
          />
          <Entity
            v-else-if="editableField.input_type === 'ENTITY'"
            :field="editableField"
            :fieldsData="getFieldsData"
          />
          <Paragraph
            v-else-if="editableField.input_type === 'PARAGRAPH'"
            :field="editableField"
          />
          <SingleLineContent
            v-else-if="editableField.input_type === 'SINGLE_LINE_CONTENT'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <Location
            v-if="editableField.input_type === 'LOCATION'"
            :field="editableField"
          />
          <AuthorizedSignature
            v-else-if="editableField.input_type === 'AUTHORIZED_SIGNATURE'"
            :field="editableField"
          />
          <ImageComponent
            v-else-if="
              editableField.input_type === 'IMAGE' ||
              editableField.input_type === 'GIF'
            "
            :field="editableField"
          />

          <VideoComponent
            v-else-if="editableField.input_type === 'VIDEO'"
            :field="editableField"
          />

          <Formula
            v-else-if="editableField.input_type === 'FORMULA'"
            :field="editableField"
            :selfTemplate="fieldsData"
            :templatesData="
              (getAllCompanyTemplatesData && getAllCompanyTemplatesData.data
                ? getAllCompanyTemplatesData.data
                : []
              ).filter((e) => !e.is_repeatable)
            "
            :data="fieldsData"
            :selfTemplateId="selectedTemplateId"
          />

          <ActionButton
            v-else-if="editableField.input_type === 'ACTION_BUTTON'"
            :field="editableField"
            :data="fieldsData"
            :fieldsData="fieldsData"
            :selfTemplateId="selectedTemplateId"
            :allFormTemplates="allFormTemplates"
          />

          <Html
            v-else-if="
              editableField.input_type === 'HTML_CONTENT' ||
              editableField.inputType === 'HTML_CONTENT'
            "
            :field="editableField"
          />
          <HorizontalLine
            v-else-if="editableField.input_type === 'HORIZONTAL_LINE'"
            :field="editableField"
            :isFromDocument="false"
          />
          <Carousel
            v-else-if="editableField.input_type === 'CAROUSEL'"
            :field="editableField"
            :isFromDocument="false"
          />
          <DIVComponent
            v-else-if="editableField.input_type === 'DIVISION'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <icon
            v-else-if="editableField.input_type === 'ICON'"
            :field="editableField"
          />
          <Calender
            v-else-if="editableField.input_type === 'CALENDER'"
            :field="editableField"
            :fieldsData="fieldsData"
            :allFormTemplates="allFormTemplates"
          />
          <AggregateFunction
            v-else-if="editableField.input_type === 'AGGREGATE_FUNCTION'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <Question
            v-else-if="editableField.input_type === 'QUESTION'"
            :field="editableField"
            :fieldsData="fieldsData"
            :standardQuestions="allStandardQuestions"
          />
          <AutoIncrement
            v-else-if="editableField.input_type === 'AUTO_INCREMENT_NUMBER'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <RandomText
            v-else-if="editableField.input_type === 'RANDOM_TEXT'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <StarRating
            v-else-if="editableField.input_type === 'STAR_RATING'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <WeekDays
            v-else-if="editableField.input_type === 'WEEKDAYS'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <DataTable
            v-else-if="editableField.input_type === 'DATA_TABLE'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <EntityTable
            v-else-if="editableField.input_type === 'ENTITY_TABLE'"
            :field="editableField"
            :allFieldsData="fieldsData"
            class="child_entity_scroll"
          />
          <Payment
            v-else-if="editableField.input_type === 'PAY_BUTTON'"
            :field="editableField"
            :fieldsData="fieldsData"
            :selfTemplate="fieldsData"
            :templatesData="
              (getAllCompanyTemplatesData.data || []).filter(
                (e) => !e.is_repeatable
              )
            "
            :data="fieldsData"
            :selfTemplateId="selectedTemplateId"
          />
          <Payables
            v-else-if="editableField.input_type === 'RECEIVE_BUTTON'"
            :field="editableField"
            :fieldsData="fieldsData"
            :selfTemplate="fieldsData"
            :templatesData="
              (getAllCompanyTemplatesData.data || []).filter(
                (e) => !e.is_repeatable
              )
            "
            :data="fieldsData"
            :selfTemplateId="selectedTemplateId"
          />
          <PayVariable
            :field="editableField"
            :fieldsData="fieldsData"
            v-if="editableField.input_type === 'PAYMENT_VARIABLE'"
          ></PayVariable>
          <Concatenate
            v-if="editableField.input_type === 'CONCATENATE'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <Audio
            v-if="editableField.input_type === 'AUDIO'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <Captcha
            v-if="editableField.input_type === 'CAPTCHA'"
            :field="editableField"
            :fieldsData="fieldsData"
          />
          <QR
            v-if="editableField.input_type === 'QR'"
            :field="editableField"
            :fieldsData="getFieldsData"
          />
          <QRReader
            v-if="editableField.input_type === 'QR_READER'"
            :field="editableField"
            :fieldsData="getFieldsData"
          ></QRReader>
          <Integration
            v-if="editableField.input_type === 'INTEGRATION'"
            :field="editableField"
            :fieldsData="getFieldsData"
          ></Integration>
          <div
            v-if="
              editableField.input_type == 'CHECKBOX' && !editableField.group_key
            "
          >
            <el-checkbox v-model="editableField.is_check_box_group"
              >Checkbox Group</el-checkbox
            >
          </div>
          <div
            v-if="
              editableField.input_type == 'CHECKBOX' && !editableField.group_key
            "
          >
            <el-checkbox v-model="editableField.forConfirmation"
              >Acceptance</el-checkbox
            >
          </div>
          <el-row
            v-if="editableField.is_check_box_group || editableField.group_key"
          >
            <el-col :span="12">
              <span>Select Group</span>
              <div class="d-flex">
                <el-select
                  default-first-option
                  placeholder="Select Group"
                  v-model="editableField.group_key"
                  clearable
                  style="width: 80%"
                  class="mr-1"
                  @change="updateEntityFields"
                >
                  <el-option
                    v-for="(name, index) in checkBoxGroupArray"
                    :value="name.key"
                    :key="index"
                    :label="name.label ? name.label : name.key"
                    >{{ name.label }}
                  </el-option>
                </el-select>
                <el-tooltip placement="top-left" content="Add Group">
                  <el-button
                    @click="openAddGroupDialog"
                    class="ml-1"
                    type="primary"
                    size="mini"
                  >
                    <i class="el-icon-circle-plus-outline"></i
                  ></el-button>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="12" v-if="editableField.group_key">
              <span>Filled By</span>
              <div>
                <el-select
                  placeholder="Filled By"
                  style="width: 80%"
                  filterable
                  v-model="checkBoxCurrentObj.filled_by"
                  :default-first-option="true"
                >
                  <el-option
                    v-for="(option, index) of filledByOptions"
                    :key="index"
                    :value="option.value"
                    :label="option.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4" class="mr-1 mt-1" v-if="editableField.group_key">
              <div>
                <span>Min</span>
                <span>
                  <!-- {{ name }} -->
                  <el-input
                    :disabled="this.editableField.field_type == 'ENTITY_FIELD'"
                    type="number"
                    v-model="checkBoxCurrentObj.min_value"
                    placeholder="Minimum Value"
                  ></el-input>
                </span>
              </div>
            </el-col>
            <el-col :span="4" class="mr-1 mt-1" v-if="editableField.group_key">
              <div>
                <span>Max</span>
                <span>
                  <el-input
                    type="number"
                    v-model="checkBoxCurrentObj.max_value"
                    placeholder="Maximum Value"
                  ></el-input>
                </span>
              </div>
            </el-col>

            <el-col :span="6">
              <div
                class="mt-2"
                v-if="
                  editableField.input_type == 'CHECKBOX' &&
                  editableField.group_key
                "
              >
                <el-checkbox v-model="checkBoxCurrentObj.show_checkbox_label"
                  >Show Label</el-checkbox
                >
              </div>
            </el-col>
          </el-row>
          <el-row v-if="editableField.forConfirmation">
            <el-col :span="24">
              <el-form>
                <div class="form-group">
                  <el-form-item>
                    <label class="field-title-label">
                      Content<span class="red-asterisk">*</span>
                    </label>
                    <el-input
                      type="textarea"
                      v-model="editableField.content"
                      placeholder="Enter content"
                      rows="10"
                      cols="200"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button v-if="editableFieldIndex !== -1" @click="deleteElement"
              >Delete Field</el-button
            >
            <el-button
              type="primary"
              @click="doneEditField"
              :disabled="disableFieldButton(editableField)"
            >
              <span v-if="editableFieldIndex !== -1">Update Field</span>
              <span v-else>Insert Field</span>
            </el-button>
          </span>
        </template>
      </dialog-component>

      <dialog-component
        :title="'Preview Template'"
        :visible="previewDialogVisible"
        class="preview-template-view"
        @before-close="previewDialogVisibleDialog"
        :containerWidth="'80%'"
        :containerMaxHeight="'70%'"
        :isShowFooter="false"
      >
        <template>
          <NewPreviewTemplate
            :name="name"
            :fieldsData="fieldsData"
            :isExecute="true"
            v-if="previewDialogVisible"
            :templateInteractiveQuestions="templateInteractiveQuestions"
            :templateInteractiveQuestionsType="templateInteractiveQuestionsType"
          ></NewPreviewTemplate>
        </template>
      </dialog-component>
    </div>
    <el-drawer
      title="Field Properties"
      :visible.sync="showFieldSettings"
      :with-header="false"
      :append-to-body="true"
      :modal="false"
      :before-close="handleCloseDrawer"
    >
      <!-- <div class="vue-tabs"> -->
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane
          label="Field Properties"
          name="first"
          v-if="this.editableField.inputType != 'HORIZONTAL_LINE'"
        >
          <FieldProperties
            v-if="showFieldSettings"
            :field="editableField"
            :fieldsData="fieldsData"
            :allFieldsData="allFieldsData"
          ></FieldProperties>
        </el-tab-pane>
        <el-tab-pane label="Styling Settings" name="second">
          <FieldSettings
            v-if="showFieldSettings"
            :field="editableField"
            :fieldsData="fieldsData"
          ></FieldSettings>
        </el-tab-pane>
        <el-tab-pane label="AI Settings" name="third">
          <AISettings
            v-if="showFieldSettings"
            :fieldData="editableField"
            :fieldsData="fieldsData"
          ></AISettings>
        </el-tab-pane>
      </el-tabs>
      <!-- </div> -->
    </el-drawer>

    <el-drawer
      title="Template Settings"
      :visible.sync="templateStyleSettingsDialogVisible"
      :with-header="true"
      class="template-settings-drawer"
    >
      <TemplateStyleSettings
        v-if="templateStyleSettingsDialogVisible"
        :data="templateStyles"
      ></TemplateStyleSettings>
    </el-drawer>

    <dialog-component
      :visible="showAddData"
      top="5%"
      :title="'Add New Group'"
      :containerWidth="getIsMobile ? '100%' : '45%'"
      @befor-close="showAddDataDialog"
      :isShowFooter="false"
    >
      <div class="px-20">
        <div class="mb-20">
          <el-form>
            <el-row>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Group Label:</span>
                  <span>
                    <el-input
                      placeholder="Group Name"
                      v-model="check_box_group_form.label"
                    ></el-input>
                  </span>
                </div>
              </el-col>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Min Value:</span
                  ><span>
                    <el-input
                      placeholder="Minimum Value"
                      v-model="check_box_group_form.min_value"
                    ></el-input>
                  </span>
                </div>
              </el-col>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Max Value:</span>
                  <span>
                    <el-input
                      placeholder="Maximum Value"
                      v-model="check_box_group_form.max_value"
                    ></el-input>
                  </span>
                </div>
              </el-col>

              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Filled By: </span
                  ><span>
                    <el-select
                      label=""
                      placeholder="Filled By"
                      v-model="editableField.filled_by"
                      filterable
                      :default-first-option="true"
                    >
                      <el-option
                        v-for="(option, index) of filledByOptions"
                        :key="index"
                        :value="option.value"
                        :label="option.name"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="mt-1">
                  <el-checkbox
                    v-model="check_box_group_form.show_checkbox_label"
                    >Show Label</el-checkbox
                  >
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer">
          <el-button
            class="mt-1"
            style="float: right"
            type="success"
            :disabled="!this.check_box_group_form.label"
            @click="submitNewGroup"
            >Submit</el-button
          >
        </span>
      </div>
      <p v-if="dialogErr" style="color: red; font-size: 14px">
        Group Name already exist
      </p>
    </dialog-component>
    <dialog-component
      :title="'Warning'"
      :visible="dialogVisibleForSavingData"
      @before-close="dialogVisibleForSavingDataDialog"
      class="warning-popup"
    >
      <div class="popUp-content">
        <span>
          <h5>It looks like you have been modifying something...</h5>
        </span>
        <span>If you leave before updating, your changes will be lost.</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForSavingData = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="backToPrevious">Confirm</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :title="'Create New Template'"
      :visible="dialogTemplatePrimaryData"
      @before-close="closeCreateNewTemplatePopup"
      class="create-new-template-popup"
    >
      <el-form label-position="right" @keyup.enter.native="handleEnterKey()">
        <div class="title-section">
          <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                  <!-- <img
                    :src="
                      logoUploadUrl +
                      '/assets/img/template-icons/' +
                      templateIconPath
                    "
                    alt="icon"
                    width="40"
                  /> -->
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
          <div class="template-titles">
            <el-form-item class="tempTitle">
              <span class="temp-title">Template Title </span>
              <br />

              <el-input
                size="medium"
                type="text"
                placeholder="Enter Template Title"
                maxlength="70"
                v-model="name"
                :readonly="!showFields"
              ></el-input>
            </el-form-item>

            <p class="error" v-if="getTemplateErrors && getTemplateErrors.name">
              {{ getTemplateErrors.name }}
            </p>
            <p
              class="error"
              v-if="getTemplateErrors && getTemplateErrors.critical_error"
            >
              {{ getTemplateErrors.critical_error }}
            </p>
            <div class="description">
              <el-form-item>
                <span class="temp-sub-title">Template Description</span>
                <br />
                <el-input
                  size="medium"
                  type="textarea"
                  placeholder="Enter Template Description"
                  v-model="description"
                  rows="2"
                ></el-input>
              </el-form-item>
              <el-row type="flex" justify="left" align="middle">
                <el-col :span="24">
                  <div class="user-types mt-1">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="10">
                        <el-checkbox v-model="templateReatingStatus">
                          Use this template as Repeatable template for adding
                          multiple data
                        </el-checkbox>
                      </el-col>
                    </el-row>
                    <p
                      class="error"
                      v-if="
                        getTemplateErrors && getTemplateErrors.is_repeatable
                      "
                    >
                      {{ getTemplateErrors.is_repeatable }}
                    </p>
                  </div>
                  <div
                    class="title temp-sub-title user-types"
                    v-if="templateReatingStatus"
                  >
                    <!-- <span>Template title</span> -->
                    This is an repeatable template. You can add multiple data,
                    So please select a primary field.
                    <!-- <span class="temp-title">Select Field</span> -->

                    <el-form-item>
                      <el-select
                        v-model="primaryField"
                        :multiple="false"
                        :collapse-tags="true"
                        placeholder="Select Primary Field"
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(field, fIndex) of fieldsData"
                          :key="fIndex"
                          :label="field.label"
                          :value="field.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                class="mt-1"
              >
                <el-col :span="10">
                  <el-checkbox v-model="templateInteractiveQuestions">
                    Use this template for questionnaire
                  </el-checkbox>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                v-if="templateInteractiveQuestions"
              >
                <el-col :span="10">
                  <el-select v-model="templateInteractiveQuestionsType">
                    <el-option
                      label="Horizontal"
                      :value="'horizontal'"
                    ></el-option>

                    <el-option label="Vertical" :value="'vertical'"></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                class="mt-1"
              >
                <el-col>
                  <el-checkbox v-model="templateTryUsingAI"
                    >Use Try Using AI</el-checkbox
                  >
                </el-col>
              </el-row>
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                class="mt-1"
              >
                <el-col>
                  <el-checkbox v-model="showSuccessNotification"
                    >Show a success notification after the template data is
                    saved or updated</el-checkbox
                  >
                </el-col>
              </el-row>
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                class="mt-1"
              >
                <el-form-item label="Print Document Template">
                  <el-select
                    placeholder="Select Document template"
                    v-model="printingDocument"
                    clearable
                  >
                    <el-option
                      v-for="(doc, i) in allDocumentTemplates"
                      :key="i + '_document'"
                      :label="doc.title"
                      :value="doc.configurable_document_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row
                type="flex"
                justify="left"
                align="middle"
                v-if="!this.$route.query.group"
              >
                <el-col :span="24">
                  <el-form-item label="Groups" class="mb-1">
                    <el-row>
                      <el-col>
                        <el-select
                          v-model="templateGroups"
                          placeholder="Select Group"
                          multiple
                          filterable
                          collapse-tags
                        >
                          <el-option
                            v-for="(item, index) of getAllEntityGroupsData"
                            v-bind:key="index"
                            :label="item.name"
                            :value="item._id"
                          ></el-option>
                        </el-select>
                        <!-- <el-col :span="1"> -->
                        <el-tooltip
                          content="Add New Group"
                          placement="top"
                          effect="light"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            @click="addGroupPopUP"
                          ></i>
                        </el-tooltip>
                        <!-- </el-col> -->
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
      <span class="dialog-footer" slot="footer">
        <div class="last-row-in-form">
          <div class="action-wrapper">
            <el-button @click="goBack" size="small">Cancel</el-button>
            <el-button
              type="primary"
              size="small"
              :disabled="
                !name.trim() ||
                (templateInteractiveQuestions &&
                  !templateInteractiveQuestionsType)
              "
              @click="checkPrimaryTemplates()"
              >Submit</el-button
            >
          </div>
        </div>
      </span>
    </dialog-component>
    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '28%'"
      @before-close="closeAddNewGroup"
      :isShowFooter="false"
    >
      <el-form>
        <div v-loading="loadingSaveGroup" class="popUp-content">
          <el-form-item class="mb-10" label="Group Title">
            <el-input
              type="text"
              v-model="groupName"
              placeholder="Enter Title"
            ></el-input>
          </el-form-item>
          <br />
          <p class="error" v-if="getError && getError.data">
            {{ getError.data.message }}
          </p>
          <br />
          <el-form-item class="text-center mt-20">
            <el-button @click="showAddGroupDialog = false">Cancel</el-button>
            <el-button
              type="success"
              @click="saveGroup"
              :disabled="!groupName || !groupName.trim()"
              class="px-20"
            >
              <i class="el-icon-check"></i> Save Group
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </dialog-component>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
    <!-- Template Build Modal -->
    <dialog-component
      :title="'Set Primary Field'"
      :containerWidth="'45%'"
      :visible="displayPrimaryFieldDialog"
      @before-close="displayPrimaryFieldDialogClose"
    >
      <el-form>
        <div class="title-section">
          <div class="icon-dropdown-selection"></div>
          <div class="template-titles">
            <div class="title">
              <!-- <span>Template title</span> -->
              This is an repeatable template. You can add multiple data, So
              please select a primary field.
              <!-- <span class="temp-title">Select Field</span> -->

              <el-form-item class="input-select mt-1">
                <el-select
                  v-model="primaryField"
                  :multiple="false"
                  :collapse-tags="true"
                  placeholder="Select Field"
                >
                  <el-option
                    v-for="(field, fIndex) of fieldsData"
                    :key="fIndex"
                    :label="field.label"
                    :value="field.key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <p class="error" v-if="getTemplateErrors && getTemplateErrors.name">
              {{ getTemplateErrors.name }}
            </p>
            <p
              class="error"
              v-if="getTemplateErrors && getTemplateErrors.critical_error"
            >
              {{ getTemplateErrors.critical_error }}
            </p>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTemplatePrimaryData = false">Cancel</el-button>
        <el-button
          class="sub-btn"
          :disabled="name == ''"
          @click="submitTemplateData()"
          >Submit</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :visible="templateRulesDialog"
      :title="'Form Rules'"
      @before-close="handleCloseFormRules"
      :containerWidth="'800px'"
      :isShowFooter="false"
    >
      <template-form-rules-widget
        :allFields="fieldsData"
        :templateRules="templateRules"
        @form-rule-added="updateFormRules"
        @form-rule-delete="deleteFormRules"
        class="popUp-content"
      >
      </template-form-rules-widget>
    </dialog-component>
    <dialog-component
      :visible="duplicateRulesModal"
      :title="'Duplicate Rules'"
      @before-close="closeDialog"
      :isShowFooter="false"
      draggable
    >
      <duplicate-data-rules-widget
        :fields="fieldsData"
        :templateData="templateData"
      >
      </duplicate-data-rules-widget>
    </dialog-component>
    <dialog-component
      :title="'Warning'"
      :visible="dialogVisible"
      :containerWidth="'30%'"
      class="warning-popup"
    >
      <div class="mt-10">
        <span>
          <h5>
            It looks like you have been modifying something... To save a rule,
            click on the '+' icon next to the delete icon
          </h5>
        </span>
        <br />
        <span>If you leave before updating, your changes will be lost.</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBackCancelMethod">Cancel</el-button>
        <el-button type="primary" @click="backPrevious">Confirm</el-button>
      </span>
    </dialog-component>
    <template-fields-entity-reationship-widget
      v-if="showTemplateFieldsRelationshipData"
      v-on:close="closeEntityRelationshipDialog"
      :entities="templateRelatedEntities"
      :fields="entityFields"
    ></template-fields-entity-reationship-widget>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import { bus } from "../../main";
import draggable from "vuedraggable";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import axios from "@/config/axios";
import chroma from "chroma-js";

import { fetchSingleCompanyTemplate } from "@/repo/templatesRepo";
import { postAPICall } from "@/helpers/httpHelper";
var iconsData = require("@/assets/data/icons.json");
var fieldsList = require("./fields.json");
import { makeKey } from "../../helpers/appHelper";
import formComponents from "./formComponents/index";
import * as mexp from "math-expression-evaluator";
import {
  updateTemplateData,
  addSingleTemplateToLocal,
} from "@/repo/templatesRepo";
import { fetchGlobalVariables } from "@/repo/globalVariables";
import { fetchGroups, addSingleGroupToLocal } from "@/repo/groupsRepo";
export default {
  name: "formTemplates-New-Template",
  components: {
    // AutoIncrement: () => import("./formComponents/AutoIncrement.vue"),
    // Address: () => import("./formComponents/Address.vue"),
    DialogComponent: () => import("../customComponents/CustomDialog.vue"),
    Currency: () => import("./formComponents/Currency.vue"),
    PhoneCountryCode: () => import("./formComponents/PhoneCountryCode.vue"),
    SingleLineText: () => import("./formComponents/SingleLineText"),
    Location: () => import("./formComponents/Location"),
    MultiLineText: () => import("./formComponents/MultiLineText"),
    Number: () => import("./formComponents/Number"),
    Date: () => import("./formComponents/Date"),
    Time: () => import("./formComponents/Time"),
    DateTime: () => import("./formComponents/DateTime"),
    DateTimeRange: () => import("./formComponents/DateTimeRange"),
    TimeRange: () => import("./formComponents/TimeRange"),
    File: () => import("./formComponents/File"),
    Select: () => import("./formComponents/Select"),
    YesOrNo: () => import("./formComponents/YesOrNo"),
    Heading: () => import("./formComponents/Heading"),
    MultiSelect: () => import("./formComponents/MultiSelect"),
    Checkbox: () => import("./formComponents/Checkbox"),
    Radio: () => import("./formComponents/Radio"),
    Calender: () => import("./formComponents/Calender"),
    RadioButtonGroup: () => import("./formComponents/RadioButtonGroup"),
    ESignature: () => import("./formComponents/ESignature"),
    GlobalVariable: () => import("./formComponents/GlobalVariable"),
    EntityVariable: () => import("./formComponents/EntityVariable"),
    Entity: () => import("./formComponents/Entity"),
    NewPreviewTemplate: () => import("./NewPreviewTemplate"),
    Paragraph: () => import("./formComponents/Paragraph"),
    SingleLineContent: () => import("./formComponents/SingleLineContent"),
    AuthorizedSignature: () => import("./formComponents/AuthorizedSignature"),
    FieldSettings: () => import("./formComponents/FieldSettings"),
    AISettings: () => import("./formComponents/AISettings"),
    FieldProperties: () => import("./formComponents/FieldProperties"),
    ImageComponent: () => import("./formComponents/Image.vue"),
    VideoComponent: () => import("./formComponents/Video.vue"),
    Formula: () => import("./formComponents/Formula.vue"),
    Icon: () => import("./formComponents/Icon.vue"),
    ActionButton: () => import("./formComponents/ActionButton.vue"),
    TemplateStyleSettings: () =>
      import("./formComponents/TemplateStyleSettings.vue"),
    Html: () => import("./formComponents/Html.vue"),
    List: () => import("./formComponents/List.vue"),
    HorizontalLine: () => import("./formComponents/HorizontalLine.vue"),
    Carousel: () => import("./formComponents/Carousel.vue"),
    DIVComponent: () => import("./formComponents/DIV.vue"),
    AggregateFunction: () => import("./formComponents/AggregateFunction.vue"),
    //   CheckBoxGroup: () =>
    // import("./formComponents/CheckBoxGroup"),
    CheckBoxGroup: () => import("./formComponents/CheckBoxGroup"),
    WeekDays: () => import("./formComponents/WeekDays.vue"),
    //view
    CurrencyView: () => import("./formComponentsView/CurrencyView.vue"),
    PhoneCountryCodeView: () =>
      import("./formComponentsView/PhoneCountryCodeView.vue"),
    DateView: () => import("./formComponentsView/DateView"),
    DateTimeView: () => import("./formComponentsView/DateTimeView"),
    DateTimeRangeView: () => import("./formComponentsView/DateTimeRangeView"),
    RadioButtonGroupView: () =>
      import("./formComponentsView/RadioButtonGroupView"),
    TimeView: () => import("./formComponentsView/TimeView"),
    TimeRangeView: () => import("./formComponentsView/TimeRangeView.vue"),
    FileView: () => import("./formComponentsView/FileView"),
    MultiLineTextView: () => import("./formComponentsView/MultiLineTextView"),
    SingleLineTextView: () => import("./formComponentsView/SingleLineTextView"),
    MultiSelectView: () => import("./formComponentsView/MultiSelectView"),
    NumberView: () => import("./formComponentsView/NumberView"),
    SelectView: () => import("./formComponentsView/SelectView"),
    YesOrNoView: () => import("./formComponentsView/YesOrNoView"),
    HeadingView: () => import("./formComponentsView/HeadingView"),
    IconView: () => import("./formComponentsView/IconView"),
    CalenderView: () => import("./formComponentsView/CalenderView"),
    CheckboxView: () => import("./formComponentsView/CheckboxView"),
    RadioView: () => import("./formComponentsView/RadioView"),
    ESignatureView: () => import("./formComponentsView/ESignatureView"),
    GlobalVariableView: () => import("./formComponentsView/GlobalVariableView"),
    EntityVariableView: () =>
      import("./formComponentsView/EntityVariableView.vue"),
    EntityView: () => import("./formComponentsView/EntityView.vue"),
    ParagraphView: () => import("./formComponentsView/ParagraphView.vue"),
    SingleLineContentView: () =>
      import("./formComponentsView/SingleLineContentView.vue"),
    AuthorizedSignatureView: () =>
      import("./formComponentsView/AuthorizedSignatureView.vue"),
    ImageView: () => import("./formComponentsView/ImageView.vue"),
    VideoView: () => import("./formComponentsView/VideoView.vue"),
    FormulaView: () => import("./formComponentsView/FormulaView.vue"),
    ActionButtonView: () => import("./formComponentsView/ActionButtonView.vue"),
    HtmlView: () => import("./formComponentsView/HtmlView.vue"),
    ListView: () => import("./formComponentsView/ListView.vue"),
    HorizontalLineView: () =>
      import("./formComponentsView/HorizontalLineView.vue"),
    CarouselView: () => import("./formComponentsView/CarouselView.vue"),
    DIVView: () => import("./formComponentsView/DIVView.vue"),
    AggregateFunctionView: () =>
      import("./formComponentsView/AggregateFunctionView.vue"),
    TemplateFieldsEntityReationshipWidget: () =>
      import("../widgets/TemplateFieldsEntityReationshipWidget.vue"),
    QuestionView: () => import("./formComponentsView/QuestionView.vue"),
    Question: () => import("./formComponents/Question.vue"),
    AutoIncrementView: () =>
      import("./formComponentsView/AutoIncrementView.vue"),
    AutoIncrement: () => import("./formComponents/AutoIncrement.vue"),
    RandomTextView: () => import("./formComponentsView/RandomTextView.vue"),
    RandomText: () => import("./formComponents/RandomText.vue"),
    StarRatingView: () => import("./formComponentsView/StarRatingView.vue"),
    StarRating: () => import("./formComponents/StarRating.vue"),
    TemplateFormRulesWidget: () =>
      import("../widgets/TemplateFormRulesWidget.vue"),
    // CheckBoxGroupView: () =>
    //   import("./formComponentsView/CheckBoxGroupView"),
    CheckBoxGroupView: () => import("./formComponentsView/CheckBoxGroupView"),
    DataTableView: () => import("./formComponentsView/DataTableView"),

    // AutoIncrementView: () => import("./formComponentsView/AutoIncrementView.vue"),
    // AddressView: () => import("./formComponentsView/AddressView.vue"),
    WeekDaysView: () => import("./formComponentsView/WeekDaysView.vue"),
    DuplicateDataRulesWidget: () =>
      import("../widgets/DuplicateDataRulesWidget.vue"),
    PayVariable: () => import("./formComponents/PayVariable.vue"),
    PayVariableView: () => import("./formComponentsView/PayVariableView.vue"),
    // Standard forms here
    VueDraggableResizable,
    draggable,
    ...formComponents,

    Payment: () => import("./formComponents/Payment.vue"),
    PaymentView: () => import("./formComponentsView/PaymentView.vue"),
    Payables: () => import("./formComponents/Payables.vue"),
    PayablesView: () => import("./formComponentsView/PayablesView.vue"),
    LocationView: () => import("./formComponentsView/LocationView"),
    DateRange: () => import("./formComponents/DateRange"),
    DateRangeView: () => import("./formComponentsView/DateRangeView"),
    Concatenate: () => import("./formComponents/Concatenate"),
    ConcatenateView: () => import("./formComponentsView/ConcatenateView"),
    EntityTable: () => import("./formComponents/EntityTable"),
    EntityTableView: () => import("./formComponentsView/EntityTableView"),
    Audio: () => import("./formComponents/Audio"),
    AudioView: () => import("./formComponentsView/AudioView"),
    Captcha: () => import("./formComponents/Captcha"),
    CaptchaView: () => import("./formComponentsView/CaptchaView"),
    QR: () => import("./formComponents/QR"),
    QRView: () => import("./formComponentsView/QRView"),
    QRReader: () => import("./formComponents/QRReader"),
    QRReaderView: () => import("./formComponentsView/QRReaderView"),
    Integration: () => import("./formComponents/Integration.vue"),
    IntegrationView: () => import("./formComponentsView/IntegrationView.vue"),
  },
  mixins: [MobileRelatedHelper, NavigationHelper, TemplateBuilderHelper],

  async created() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.keyCode === 46) {
        //  that.deleteActiveElement();
      } else if (
        evt.keyCode === 37 ||
        evt.keyCode === 38 ||
        evt.keyCode === 39 ||
        evt.keyCode === 40
      ) {
        // evt.preventDefault();
        that.handleVDRKeyDown(evt);
      }
    });
    document.addEventListener("keyup", function (evt) {
      // if (evt.keyCode === 46 || evt.keyCode === 8) {
      //   that.deleteActiveElement();
      // }
      if (evt.keyCode === 46) {
        that.deleteActiveElement();
      } else if (
        evt.keyCode === 37 ||
        evt.keyCode === 38 ||
        evt.keyCode === 39 ||
        evt.keyCode === 40
      ) {
        that.handleVDRKeyDown(evt);
        // evt.preventDefault();
      }
    });
    document.addEventListener("dragover", this.onDocumentDrag);
    document
      .querySelectorAll(".field-element")
      .forEach((element) =>
        element.addEventListener("mousedown", this.onDraggableElementMouseDown)
      );

    //querySelectorAll instead on getElementsByClassName

    document.addEventListener("touchmove", this.onDocumentDragMobile);
    document
      .querySelectorAll(".field-element")
      .forEach((element) =>
        element.addEventListener(
          "touchstart",
          this.onDraggableElementMouseDownMobile
        )
      );

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
      this.$store.commit("navigationOpen/setIsRSOpen", false, {
        root: true,
      });
    }
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "z" && that.history.length) {
        let lastActivity = that.history[that.history.length - 1];
        if (
          lastActivity?.action == "add" &&
          (lastActivity.index || lastActivity.index == 0)
        ) {
          that.fieldsData.splice(lastActivity.index, 1);
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        } else if (lastActivity?.action == "positionChange") {
          that.fieldsData.map((el, index) => {
            if (index == lastActivity.index) {
              //  el.x = lastActivity.data.x;
              // el.y = lastActivity.data.y;
              let tempx = lastActivity.data.x;
              lastActivity.data.x = el.x;
              el.x = tempx;

              let tempy = lastActivity.data.y;
              lastActivity.data.y = el.y;
              el.y = tempy;
            }
          });
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        } else if (lastActivity?.action == "delete") {
          that.fieldsData.push(lastActivity.data);
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        }
      } else if (
        event.ctrlKey &&
        event.key === "y" &&
        that.redohistory.length
      ) {
        let lastofredoActivity = that.redohistory[that.redohistory.length - 1];
        if (
          lastofredoActivity?.action == "add" &&
          (lastofredoActivity.index || lastofredoActivity.index == 0)
        ) {
          that.fieldsData.push(lastofredoActivity.data);
          that.history.push(lastofredoActivity);
          that.redohistory.splice(that.redohistory.length - 1, 1);
        } else if (lastofredoActivity?.action == "positionChange") {
          that.fieldsData.map((el, index) => {
            if (index == lastofredoActivity.index) {
              //  el.x = lastofredoActivity.data.x;

              // el.y = lastofredoActivity.data.y;
              let rtempx = lastofredoActivity.data.x;
              lastofredoActivity.data.x = el.x;
              el.x = rtempx;

              let rtempy = lastofredoActivity.data.y;
              lastofredoActivity.data.y = el.y;
              el.y = rtempy;
            }
          });
          that.history.push(lastofredoActivity);

          that.redohistory.splice(that.redohistory.length - 1, 1);
        } else if (lastofredoActivity?.action == "delete") {
          that.fieldsData.splice(lastofredoActivity.index, 1);
          that.history.push(lastofredoActivity);
          that.redohistory.splice(that.redohistory.length - 1, 1);
        }
      }
    });
  },
  async mounted() {
    this.loading = true;
    window.addEventListener("keydown", this.handleKeyDown);
    this.resetStore();
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    let getAllGlobalVariables;
    this.getAllFieldList();
    bus.$on("fileUploading", (data) => {
      this.uploadingSenderFiles = data;
    });
    bus.$on("fieldData", (data) => {
      this.fieldsData = data;
    });
    bus.$on("groupDetals", (data) => {
      this.groups = data;
    });
    bus.$on("groupFieldUpdateOnChange", (data) => {
      this.checkBoxCurrentObj = data;
    });
    bus.$on("openAddNew", (data) => {
      this.showNewGroupAdd = data;
    });
    if (this.$route.query.default_template_id) {
      const isDefaultTemplate = this.$route.query.default_template_id
        ? true
        : false;
      await this.setTemplateInfo(
        this.$route.query.default_template_id,
        isDefaultTemplate
      );
    }
    if (this.$route.params.template_id) {
      const isDefaultTemplate = false;
      this.selectedTemplateId = this.$route.params.template_id;
      this.dialogTemplatePrimaryData = false;

      await this.setTemplateInfo(this.selectedTemplateId, isDefaultTemplate);
      if (this.free_style == undefined) {
        this.free_style = true;
      }
    } else {
      this.dialogTemplatePrimaryData = true;
    }

    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );
    this.loading = false;
    this.loadingText = "";
    this.getCompanyInfo();
    [this.allFormTemplates, getAllGlobalVariables] = await Promise.all([
      this.fetchAllTemplates(),
      fetchGlobalVariables(),
      this.fetchCompanyGroups(),
      this.fetchAllStandardGlobalVariables(),
      this.fetchAllCompanyDocTemplates(),
    ]);
    if (getAllGlobalVariables?.data) {
      this.allGlobalVariables = getAllGlobalVariables.data;
      this.allGlobalVariables.map((e) => {
        this.allGlobalVariablesObject[e._id] = e;
      });
    }
  },
  data() {
    return {
      formHeight: 5000,
      checkBoxGroupArr: [],
      checkBoxGroupArray: [],
      current_group_index: -1,
      checkBoxGrpObj: {},
      checkBoxCurrentObj: {},
      check_box_group_form: {
        label: "",
        key: "",
        filled_by: "",
        min_value: 1,
        max_value: 1,
      },
      filledByOptions: [
        { name: "SENDER", value: "SENDER" },
        { name: "RECEIVER", value: "RECEIVER" },
        { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" },
      ],
      deltaXToMove: 0,
      deltaYToMove: 0,
      deltaXForNearestField: 4,
      deltaYForNearestField: 4,
      posStep: 1,
      searchIcon: "",
      searchIconResult: [],
      posStepShift: 5,
      entityFieldSets: "",
      isSelectView: false,
      templates: [],
      history: [],
      redohistory: [],
      uploadingSenderFiles: [],
      showNewGroupAdd: false,
      checkPrimaryTemplate: false,
      fieldActions: [],
      fieldActions2: [],
      fieldActions3: [],
      advancedFieldActions: [],
      utilityComponents: [],
      fieldsData: [],
      dialogFormVisible: false,
      dialogTemplatePrimaryData: false,
      editableField: {
        styles: {
          color: "",
          background: "",
          border_size: 0,
          border_color: "",
          border_type: "",
          input_background: "",
          label_color: "",
          labelStyle: "",
        },
        properties: {
          is_unique: false,
          personal_data: false,
          personal_data_type: "",
          is_encrypted: false,
        },
        ai_settings: {
          isAIEnabled: false,
          selectedOperation: null,
          selectedSourceField: null,
          selectedAdditionalFields: [],
          selectedStyle: null,
          runOnAction: [],
          realtimeButtonContent: "",
          userPrompt: "",
        },
        tryUsingAISetting: {
          userSelectedOperation: null,
          additonalFields: [],
          outputField: null,
          manualPrompt: "",
        },
      },
      editableFieldIndex: -1,
      folderGroupsObject: {
        1: "Folder 1",
        2: "Folder 2",
        3: "Folder 3",
      },
      name: "",
      description: "",
      free_style: true,
      type: "",
      loading: false,
      previewDialogVisible: false,
      defaultTemplatedId: null,
      dialogVisibleForSavingData: false,
      showFields: true,
      selectedTemplateId: null,
      activatedItem: null,
      activatedItemIndex: -1,
      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      loadingText: "",
      iconPickerdialogVisible: false,
      iconList: iconsData,
      templateIconPath: "block-portfolio.svg",
      userPrimaryTemplate: false,
      titles: [],
      temlateTitleDialogVisible: false,
      templateStyleSettingsDialogVisible: false,
      templateSharingMode: true,
      templateReatingStatus: false,
      userTypes: [],
      addUserTypeTitle: {
        usertype: "",
        title: "",
      },
      showFieldSettings: false,
      templateStyles: {},
      centerDialogVisible: false,
      fullscreenDialog: false,
      actionButtonTemplateFields: {},
      fetchingActionButtonTemplateFields: false,
      displayPrimaryFieldDialog: false,
      primaryField: "",
      activeName: "first",
      showAddData: false,
      addGroup: {
        label: "",
        key: "",
        max_value: 0,
        min_value: 0,
      },
      groups: [],
      dialogErr: false,
      dragItemHeight: 40,
      deltaX: 0,
      deltaY: 0,
      mouseX: 0,
      mouseY: 0,
      isDragging: false,
      currentActiveIndex: "",
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      templateGroups: [],
      templateInteractiveQuestions: false,
      templateInteractiveQuestionsType: "",
      templateTryUsingAI: false,
      refreshFields: false,
      showTemplateFieldsRelationshipData: false,
      entityFields: [],
      allStandardQuestions: [],
      //form rules
      templateRules: [],
      templateRulesDialog: false,
      //duplicate rules
      dialogVisible: false,
      duplicateRulesModal: false,
      templateData: {
        all_duplicate_rules: [],
        duplicate_message:
          "With this data already $entity_name data is created",
      },

      selectedDataTableIndex: -1,
      selectedDataTableFields: [],
      reRenderDataTable: false,
      allGlobalVariables: [],
      getAllEntityGroupsData: [],
      allGlobalVariablesObject: {},
      allFormTemplates: [],
      printingDocument: "",
      allDocumentTemplates: [],
      items: [],
      selectedItems: [],
      field: "",
      showSuccessNotification: true,
    };
  },
  computed: {
    computedStyle() {
      let style = `
        position: relative;
        top: 10px;
        max-width: 1300px;
        overflow-y: scroll;
        scrollbar-width: thin;
      `;

      if (!this.free_style) {
        style += `height: calc(100vh - 70px) !important;`;
      } else {
        style += `width: 100%;height: calc(100vh - 100px) !important`;
      }

      return style;
    },
    getFormContainerSize() {
      let style = ``;
      if (!this.free_style) {
        style += `width: 65%;box-shadow: rgba(87, 100, 126, 0.21) 0px 4px 15px;left:16vw;background-color:white;`;
      } else {
        style = ``;
      }
      return style;
    },
    getStylediv() {
      return `min-height: ${this.formHeight}px;` + this.getElementStyle();
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateAddData",
      "getTemplateUpdateStatus",
      "getTemplateUpdateData",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
      "getImageUploadData",
      "getAllCompanyTemplatesData",
      "getDuplicateFormTemplateTitle",
      "getDuplicateFormTemplateTitleError",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getAuthenticationStatus",
      "getWorkspaceList",
      "getUserType",
      "getSwitchWorkspaceError",
      "getPermissionList",
      "getPermissions",
      "getGlobalFiltervalue",
      "getAuthenticationDetails",
      "getUserTypeList",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
      "getContactLoginSlug",
    ]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getPrimaryEntityDataStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
      "getRepeatableTemplateIds",
      "getFormbuilderTemplatesDataUpdateStatus",
      "getFormbuilderTemplatesDataUpdateData",
      "getRequiredTemplatesData",
      "getUploadAndUpdateDocument",
      "getformtemplateinvoiceinfo",
    ]),
    ...mapGetters("userTypes", ["getUserTypes"]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),

    ...mapGetters("entityGroups", [
      //"getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),

    ...mapGetters("entities", ["getTemplateRelatedEntities"]),
    ...mapGetters("globalVariables", [
      "getAllStandardGlobalVariables",
      "getAllGlobalVariables",
    ]),
    ...mapGetters("documents", ["getAllTemplates"]),
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });
      return obj;
    },
    modalTitle() {
      if (
        this.editableField &&
        this.fieldActionsObject &&
        this.editableField.input_type != "TIME"
      ) {
        return `${this.editableFieldIndex > -1 ? "Edit" : "Add"} ${
          this.fieldActionsObject[this.editableField.input_type]
            ? this.fieldActionsObject[this.editableField.input_type].name
            : this.editableField.input_type
        }`;
      } else if (
        this.editableField &&
        this.fieldActionsObject &&
        this.editableField.input_type == "TIME"
      ) {
        return `${this.editableFieldIndex > -1 ? "Edit" : "Add"} ${
          this.editableField.is_fixed ? "Fixed Time" : "Time"
        }`;
      }
      return "";
    },
    getAdminUserType() {
      return (this.getUserTypes.data || []).find(
        (userType) => userType.name == "ADMIN"
      );
    },
    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED" ||
          inputType === "HYPER_LINK"
        ) {
          styles.push("single-linfe-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "ZIP_CODE" ||
          inputType == "FAX"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },
    getTemplateSlug() {
      return this.name.trim().toLowerCase().split(" ").join("-");
    },
    allUserTypes() {
      return (this.getUserTypes.data || []).filter(
        (user) => user.name != "ADMIN"
      );
    },
    allFieldsData() {
      let result = [];
      let allFields = JSON.parse(JSON.stringify(this.fieldsData));
      allFields.forEach((field) => {
        if (
          field.inputType == "DATA_TABLE" ||
          field.input_type == "ENTITY_TABLE"
        ) {
          field.data_table_columns.forEach((el) => {
            el.label = field.label + " - " + el.label;
            result.push(el);
          });
        } else {
          result.push(field);
        }
      });
      return result;
    },
    getFieldsData() {
      if (this.editableField.data_table_field_index > -1) {
        let fields = JSON.parse(
          JSON.stringify(
            this.fieldsData[this.editableField.data_table_field_index]
          )
        );
        if (
          !fields.data_table_columns ||
          !Array.isArray(fields.data_table_columns)
        ) {
          return this.fieldsData;
        }
        return [
          ...this.fieldsData,
          ...fields.data_table_columns.map((e) => {
            // if (!e.key.includes(":")) {
            //   e.label =
            //     this.fieldsData[this.editableField.data_table_field_index]
            //       .label +
            //     " - " +
            //     e.label;
            //   e.key =
            //     this.fieldsData[this.editableField.data_table_field_index].key +
            //     ":" +
            //     e.key;
            // }
            return e;
          }),
        ];
      }
      return this.fieldsData;
    },
  },
  methods: {
    handleFormStyleChange() {
      if (!this.free_style) {
        if (this.fieldsData.length > 0) {
          this.fieldsData[0].y = 30; // Set the first field's y position to 0 (or any starting y position)
        }

        // Loop through each field starting from the second one
        for (let i = 1; i < this.fieldsData.length; i++) {
          // Set the current field's y position to the previous field's y + height + 10 (spacing)
          this.fieldsData[i].y =
            this.fieldsData[i - 1].y + this.fieldsData[i - 1].height + 10;
        }
        this.fieldsData.forEach((e) => {
          e.width = 750;
          e.x = 40;
        });
      }
    },
    async fetchAllCompanyDocTemplates() {
      await this.$store.dispatch("documents/fetchAllTemplates", {
        get_all: true,
      });
      this.allDocumentTemplates =
        this.getAllTemplates && this.getAllTemplates.data
          ? this.getAllTemplates.data
          : [];
    },
    resetStore() {
      this.$store.commit(
        "entities/setAllEntitiesInTemplate",
        {},
        {
          root: true,
        }
      );
      this.$store.commit(
        "companyTemplates/setTemplateDataTempVariable",
        {},
        { root: true }
      );
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        //await this.$store.dispatch(
        //  "company/fetchCompany",
        //  this.getActiveWorkspace.company_id
        //);
        if (this.getCompanyDetails) {
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
          await Promise.all([this.applyTheme()]);
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async applyTheme() {
      // Apply theme using company info
      // Ensure that this.companyInfo is set before applying theme
      document.documentElement.style.setProperty(
        "--primary-color",
        this.getCompanyDetails.dialog_header_color
      );
      document.documentElement.style.setProperty(
        "--primary-contrast-color",
        this.getCompanyDetails.dialog_header_text_color
      );
      document.documentElement.style.setProperty(
        "--sidemenu-background-color",
        this.getCompanyDetails.sidemenu_background
      );
      document.documentElement.style.setProperty(
        "--sidemenu-text-color",
        this.getCompanyDetails.sidemenu_text
      );
      const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-color")
        .trim();
      const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
      const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
      document.documentElement.style.setProperty(
        "--darker-primary-color",
        darkerPrimaryColor
      );
      document.documentElement.style.setProperty(
        "--lighter-primary-color",
        lighterPrimaryColor
      );
      // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
      // document.documentElement.style.setProperty('--text-secondary-color', 'white');

      const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--secondary-color")
        .trim();

      const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

      document.documentElement.style.setProperty(
        "--darker-secondary-color",
        darkerSecondaryColor
      );
    },
    closeDialog() {
      this.duplicateRulesModal = false;
    },
    dialogVisibleForSavingDataDialog() {
      this.dialogVisibleForSavingData = false;
    },
    closeAddNewGroup() {
      this.showAddGroupDialog = false;
    },
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    previewDialogVisibleDialog() {
      this.previewDialogVisible = false;
    },
    showAddDataDialog() {
      this.showAddData = false;
    },
    copyField(key, insertIndex = 12) {
      const originalField = this.fieldsData.find((field) => field.key === key);

      if (originalField) {
        const baseKey = originalField.key.split("_duplicate_")[0];
        const duplicateCount = this.fieldsData.filter((field) =>
          field.key.startsWith(baseKey)
        ).length;
        const duplicatedField = JSON.parse(JSON.stringify(originalField));
        const uniqueKey = `${baseKey}_duplicate_${duplicateCount}`;
        duplicatedField.key = uniqueKey;
        if (duplicatedField.is_contentFields) {
          const contentParts = duplicatedField.content.split("-");
          const baseContent = contentParts[0] || "Content";
          duplicatedField.content = `${baseContent}-${duplicateCount}`;
        } else {
          const labelParts = duplicatedField.label.split("-");
          const baseLabel = labelParts[0] || "Field";
          duplicatedField.label = `${baseLabel}-${duplicateCount}`;
        }
        this.fieldsData.splice(insertIndex, 0, duplicatedField);
      } else {
        console.warn(`Field with key "${key}" not found.`);
      }
    },

    updateFieldProperty(index, properties) {
      this.fieldsData[index] = { ...this.fieldsData[index], ...properties };
    },
    goBackCancelMethod() {
      this.dialogVisible = false;
    },
    handleCloseFormRules() {
      bus.$emit("form-rule-dialog-closed", true);
      this.templateRulesDialog = false;
    }, //duplicate data rules
    openDuplicateRulesPopup() {
      this.duplicateRulesModal = true;
    },

    deleteFormRules(index) {
      this.templateRules.splice(index.index, 1);
    },
    updateFormRules(data) {
      if (data.index == -1) {
        this.templateRules.push(data);
      } else {
        this.templateRules[data.index] = { ...data };
      }
    },
    openRulesPopup() {
      this.fieldsData.map(
        (e) => (e.data_type = e?.validations?.data_type || "STRING")
      );
      this.fieldsData = this.fieldsData.map((e) => {
        if (e?.is_global_variable && e?.global_variable_id) {
          if (e.inputType == "SELECT") {
            let globalVaribale = (this.allGlobalVariables || []).find(
              (el) => el._id == e.global_variable_id
            );
            if (globalVaribale && globalVaribale.options) {
              e.options = [...globalVaribale.options];
            }
          }
        }
        return e;
      });

      // Open the template rules dialog
      this.templateRulesDialog = true;
    },

    getQuestionIndex(key) {
      if (key) {
        const index = this.fieldsData
          .filter((e) => e.type == "FIELD")
          .findIndex((e) => e.key == key);
        if (index > -1) {
          return index + 1;
        }
      }
      return "";
    },
    goBack() {
      this.dialogTemplatePrimaryData = false;
    },
    searchIconName(event) {
      if (this.searchIcon) {
        this.searchIconResult = this.iconList.filter((el) =>
          el.name.includes(event.toLowerCase())
        );
      }
    },
    async fetchAllStandardGlobalVariables() {
      await this.$store.dispatch(
        "globalVariables/fetchStandardGlobalVariables",
        {
          input_type: "QUESTION",
        }
      );
      if (this.getAllStandardGlobalVariables) {
        this.allStandardQuestions = [];
        this.allStandardQuestions = this.getAllStandardGlobalVariables.filter(
          (e) => e.input_type == "QUESTION"
        );
      }
    },
    getQuestionareXvalue() {
      let sum = 100;
      return sum;
    },

    /* fields position changung */
    moveFieldsTop(index) {
      if (index == 0) {
        return;
      }

      let currentField = { ...this.fieldsData[index] };
      this.fieldsData[index] = { ...this.fieldsData[index - 1] };
      this.fieldsData[index - 1] = currentField;
      this.refreshFields = true;
      setTimeout(() => {
        this.refreshFields = false;
      }, 1000);
    },

    moveFieldsBottom(index) {
      if (index == this.fieldsData.length - 1) {
        return;
      }

      let currentField = { ...this.fieldsData[index] };
      this.fieldsData[index] = { ...this.fieldsData[index + 1] };
      this.fieldsData[index + 1] = currentField;
      this.refreshFields = true;
      setTimeout(() => {
        this.refreshFields = false;
      });
    },
    /*  ***Fetch Groups  start  **** */
    async fetchCompanyGroups() {
      try {
        const groups = await fetchGroups({
          get_all: true,
        });
        if (groups?.data) {
          this.getAllEntityGroupsData = [...groups.data];
        }
      } catch (e) {
        console.log("fetchCompanyGroups", e);
      }
    },
    /*  ***Fetch Groups  end  **** */
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.groupName,
        description: "",
        entities: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        await addSingleGroupToLocal(this.getSingleEntityGroupData.data);
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.groupName = "";
        this.showAddGroupDialog = false;
        this.templateGroups.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },
    isSelected(field) {
      return this.selectedItems.includes(field.key);
    },
    onFieldClick(event) {
      const item = this.activatedItem;
      if (event.ctrlKey) {
        const index = this.selectedItems.indexOf(item.key);
        if (index === -1) {
          this.selectedItems.push(item.key);
        } else {
          this.selectedItems.splice(index, 1);
        }
      } else {
        this.selectedItems = [item];
      }
      this.selectedItems.forEach((selectedKey) => {
        this.fieldsData.find((field) => field.key === selectedKey);
      });
    },
    handleKeyDown(event) {
      if (event.key === "Delete") {
        if (this.selectedItems.length > 0) {
          this.fieldsData = this.fieldsData.filter((field) => {
            return !this.selectedItems.includes(field.key);
          });
          this.selectedItems = [];
        }
      }
    },
    // start page
    showAlignLines(x, y, isUsingDelta = true) {
      if (this.templateInteractiveQuestions) {
        return;
      }
      this.deltaXToMove = 0;
      this.deltaYToMove = 0;

      let rtnPos = this.getNearestFieldByY(x, y, isUsingDelta);
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        var line = document.getElementById("top_line");

        line.style.display = "inline-block";
        var x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        var y1 = rtnPos.y;
        var x2 = rtnPos.x;
        var y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
        // rtn = false;
      } else {
        this.hideElementById("top_line");
      }
      // middle line
      rtnPos = this.getNearestFieldByY(
        x,
        y + this.activatedItem.height / 2,
        isUsingDelta
      );
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        line = document.getElementById("middle_horizontal_line");
        line.style.display = "block";
        x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        y1 = rtnPos.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("middle_horizontal_line");
      }

      // bottom line
      rtnPos = this.getNearestFieldByY(
        x,
        y + this.activatedItem.height,
        isUsingDelta
      );

      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        line = document.getElementById("bottom_line");
        line.style.display = "block";
        x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        y1 = rtnPos.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("bottom_line");
      }

      // left line
      rtnPos = this.getNearestFieldByX(x, y, isUsingDelta);
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("left_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("left_line");
      }

      // middle vertical line
      rtnPos = this.getNearestFieldByX(
        x + this.activatedItem.width / 2,
        y,
        isUsingDelta
      );
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("middle_vertical_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("middle_vertical_line");
      }

      // right line
      rtnPos = this.getNearestFieldByX(
        x + this.activatedItem.width,
        y,
        isUsingDelta
      );
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("right_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("right_line");
      }
      return true;
    },
    backToPrevious() {
      this.$router.go(-1);
      //  this.$router.push({ path: "/templates" });
      this.dialogVisible = false;
    },
    backPrevious() {
      this.dialogVisible = false;
    },
    chechFieldPosition(x, y) {
      let pageBoundary = this.getPagesBoundaries(y);
      let parentField = [];
      if (
        this.activatedItem?.is_in_division &&
        this.activatedItem.is_in_division != ""
      ) {
        parentField = this.fieldsData.filter(
          (field) => field.key === this.activatedItem.is_in_division
        );

        if (
          x > parentField[0].x &&
          x + this.activatedItem.width <
            pageBoundary.pageOffset + pageBoundary.pageWidth &&
          y > parentField[0].y &&
          y + this.activatedItem.height <
            parentField[0].y + parentField[0].height
        ) {
          return true;
        } else {
          this.activatedItem.is_in_division = "";
        }
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + this.activatedItem.width >
            pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return true;
      }
      document.querySelectorAll(".el-date-picker").forEach((el) => {
        el.style.visibility = "hidden";
      });
      return this.showAlignLines(x, y);
    },
    getNearestFieldByX(x, y, isUsingDelta = true) {
      var deltaXForNearestField = isUsingDelta ? this.deltaXForNearestField : 1;
      var deltaX = 0;

      let pageBoundary = this.getPagesBoundaries(y);
      let pageStartY = pageBoundary.pageNumber * pageBoundary.pageHeight;
      let pageEndY = (pageBoundary.pageNumber + 1) * pageBoundary.pageHeight;

      var nearestX = -1;
      var yForNearestX = -1;
      var diffVal = deltaXForNearestField + 1;
      for (var i = 0; i < this.fieldsData.length; i++) {
        let el = this.fieldsData[i];
        if (
          el.x == this.activatedItem.x &&
          el.y == this.activatedItem.y &&
          el.width == this.activatedItem.width &&
          el.height == this.activatedItem.height
        ) {
          continue;
        }
        if (el.y >= pageStartY && el.y <= pageEndY) {
          if (Math.abs(el.x - x) <= deltaXForNearestField) {
            if (Math.abs(el.x - x) < diffVal) {
              nearestX = el.x;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x - x);
            }
          } else if (
            Math.abs(el.x + el.width / 2 - x) <= deltaXForNearestField
          ) {
            if (Math.abs(el.x + el.width / 2 - x) < diffVal) {
              nearestX = el.x + el.width / 2;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x + el.width / 2 - x);
            }
          } else if (Math.abs(el.x + el.width - x) <= deltaXForNearestField) {
            if (Math.abs(el.x + el.width - x) < diffVal) {
              nearestX = el.x + el.width;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x + el.width - x);
            }
          }
        }
      }

      deltaX = nearestX - x;

      return {
        x: nearestX,
        y: yForNearestX,
        deltaX,
      };
    },

    getNearestFieldByY(x, y, isUsingDelta = true) {
      var deltaYForNearestField = isUsingDelta ? this.deltaYForNearestField : 1;

      var deltaY = 0;
      let pageBoundary = this.getPagesBoundaries(y);
      let pageStartY = pageBoundary.pageNumber * pageBoundary.pageHeight;
      let pageEndY = (pageBoundary.pageNumber + 1) * pageBoundary.pageHeight;

      var nearestY = -1;
      var xForNearestY = -1;
      var diffVal = deltaYForNearestField + 1;

      for (var i = 0; i < this.fieldsData.length; i++) {
        let el = this.fieldsData[i];
        if (
          el.x == this.activatedItem.x &&
          el.y == this.activatedItem.y &&
          el.width == this.activatedItem.width &&
          el.height == this.activatedItem.height
        ) {
          continue;
        }
        if (el.y >= pageStartY && el.y <= pageEndY) {
          if (Math.abs(el.y - y) <= deltaYForNearestField) {
            if (Math.abs(el.y - y) < diffVal) {
              nearestY = el.y;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y - y);
            }
          } else if (
            Math.abs(el.y + el.height / 2 - y) <= deltaYForNearestField
          ) {
            if (Math.abs(el.y + el.height / 2 - y) < diffVal) {
              nearestY = el.y + el.height / 2;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y + el.height / 2 - y);
            }
          } else if (Math.abs(el.y + el.height - y) <= deltaYForNearestField) {
            if (Math.abs(el.y + el.height - y) < diffVal) {
              nearestY = el.y + el.height;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y + el.height - y);
            }
          }
        }
      }

      deltaY = nearestY - y;

      return {
        x: xForNearestY,
        y: nearestY,
        deltaY,
      };
    },

    // eslint-disable-next-line no-unused-vars
    getPagesBoundaries(y) {
      let pageBreaks = [];
      let h = 0;
      if (document.getElementById("wrapper")) {
        let maxLength = document.getElementById("wrapper").childNodes.length;

        for (let i = 0; i < maxLength; i++) {
          h = h + document.getElementById("wrapper").childNodes[i].height;
          pageBreaks.push(h);
        }
        // let pageNumber = pageBreaks.findIndex((page, index) => {
        //   if (y <= page + index * 6) {
        //     return page;
        //   }
        // });
        let data = {
          pageOffset:
            document.getElementById("wrapper").childNodes[0].offsetLeft,
          pageWidth: 1197,
          pageHeight: 1347,

          pageNumber: 0,
        };
        return data;
      }
      return {
        pageOffset: 0,
        pageWidth: 953,
        pageHeight: 1347,

        pageNumber: 0,
      };
    },

    moveFieldByDelta(deltaX, deltaY) {
      this.activatedItem.x += deltaX;
      this.activatedItem.y += deltaY;
    },

    handleVDRKeyDown(event) {
      if (
        document.activeElement &&
        (document.activeElement.tagName == "TEXTAREA" ||
          (document.activeElement.tagName == "INPUT" &&
            document.activeElement.getAttribute("type") !== "radio" &&
            document.activeElement.getAttribute("type") !== "checkbox"))
      ) {
        return;
      }
      if (this.activatedItem != null) {
        if (this.activatedItem.x < 0) {
          this.activatedItem.x = 0;
        }
        if (this.activatedItem.y < 0) {
          this.activatedItem.y = 0;
        }
        switch (event.keyCode) {
          case 37:
            // arrow left
            this.activatedItem.x -= event.shiftKey
              ? this.posStepShift
              : this.posStep;
            this.showAlignLines(
              this.activatedItem.x,
              this.activatedItem.y,
              false
            );
            setTimeout(() => {
              this.hideAllAlignLines(false);
            }, 3000);
            event.preventDefault();
            break;
          case 38:
            /* arrow up */
            this.activatedItem.y -= event.shiftKey
              ? this.posStepShift
              : this.posStep;
            this.showAlignLines(
              this.activatedItem.x,
              this.activatedItem.y,
              false
            );
            setTimeout(() => {
              this.hideAllAlignLines(false);
            }, 3000);
            event.preventDefault();
            break;
          case 39:
            /* arrow right */
            this.activatedItem.x += event.shiftKey
              ? this.posStepShift
              : this.posStep;
            this.showAlignLines(
              this.activatedItem.x,
              this.activatedItem.y,
              false
            );
            setTimeout(() => {
              this.hideAllAlignLines(false);
            }, 3000);
            event.preventDefault();
            break;
          case 40:
            /* arrow down */
            this.activatedItem.y += event.shiftKey
              ? this.posStepShift
              : this.posStep;
            this.showAlignLines(
              this.activatedItem.x,
              this.activatedItem.y,
              false
            );
            setTimeout(() => {
              this.hideAllAlignLines(false);
            }, 3000);
            event.preventDefault();
            break;
        }
      }
    },
    handleVDRMouseUp() {
      this.moveFieldByDelta(this.deltaXToMove, this.deltaYToMove);
      this.deltaXToMove = 0;
      this.deltaYToMove = 0;
    },
    hideAllAlignLinesWithDelay() {
      let self = this;
      setTimeout(() => {
        self.hideElementById("top_line");
        self.hideElementById("middle_horizontal_line");
        self.hideElementById("bottom_line");
        self.hideElementById("left_line");
        self.hideElementById("middle_vertical_line");
        self.hideElementById("right_line");
        this.isFromFieldActivated = false;
      }, 700);
    },
    hideAllAlignLines(isUsingDelta = true) {
      if (this.isFromFieldActivated) {
        return;
      }
      this.hideElementById("top_line");
      this.hideElementById("middle_horizontal_line");
      this.hideElementById("bottom_line");
      this.hideElementById("left_line");
      this.hideElementById("middle_vertical_line");
      this.hideElementById("right_line");

      if (isUsingDelta) {
        this.handleVDRMouseUp();
      }
    },
    hideElementById(elId) {
      if (this.templateInteractiveQuestions) {
        return;
      }
      var line = document.getElementById(elId);
      line.style.display = "none";
    },
    // end page

    async checkPrimaryTemplates() {
      if (this.userPrimaryTemplate && !this.userTypes.length) {
        this.checkPrimaryTemplate = true;
      } else {
        if (!this.$route.params.template_id) {
          let params = {
            name: this.name,
          };
          await this.$store.dispatch(
            "companyTemplates/fetchDupliecateTitle",
            params
          );

          if (this.getDuplicateFormTemplateTitleError.critical_error) {
            this.dialogTemplatePrimaryData = true;
            this.checkPrimaryTemplate = true;
            this.name = "";
            this.$notify.error({
              title: "Error",
              message: "Template already existed with same title.!",
            });
          } else {
            this.dialogTemplatePrimaryData = false;
            this.checkPrimaryTemplate = false;
          }
        } else {
          this.dialogTemplatePrimaryData = false;
          this.checkPrimaryTemplate = false;
        }
      }
    },
    getDefaultSizeRangesByFieldType() {
      let data = this.fieldActionsObject;
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },

    calculateRelativeLeft(x) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
      //   );
      // }
      return x;
    },

    calculateRelativeTop(y) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
      //   );
      // }
      return y;
    },
    calculateRelativeWidth(width) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
      //   );
      // }
      return width;
    },
    setSizeAndPositionForElements(elements, templateId) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...{
            template_id: templateId,
          },
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }

        field.active = false;
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },

    handleActionClick(inputType, timeFixed = false) {
      let field = { ...this.defaultFieldData() };
      field.input_type = inputType;
      field = { ...field, ...this.getDimentions(inputType) };
      field = {
        ...field,
        color: "",
        background: "",
        border_size: 0,
        border_color: "",
        border_type: "",
        input_background: "",
        label_color: "",
        labelStyle: "",
        is_global_variable: false,
      };
      if (!this.free_style) {
        field.width = 750;
      }
      if (field?._id) {
        delete field._id;
      }
      if (inputType == "PAY_BUTTON" && !field.validations["currency"]) {
        field.validations["currency"] = "USD";
      }
      if (inputType === "DATA_TABLE") {
        if (
          !field.properties.enable_plus_button &&
          !field.properties.enable_minus_button
        ) {
          field.properties.enable_plus_button = true;
          field.properties.enable_minus_button = true;
        }
      }
      if (inputType == "HORIZONTAL_LINE") {
        this.editableField = JSON.parse(JSON.stringify(field));
        this.editableField["content"] =
          inputType == "DIVISION" ? "<div></div>" : "<hr />";

        this.fieldsData.push(this.editableField);
        let modifiedData = JSON.parse(JSON.stringify(this.fieldsData));
        this.fieldsData = this.getFieldsFromElements(modifiedData);
        this.editableField = {};
      } else {
        // if(inputType == "QUESTION"){
        //   this.fieldsData.x
        // }
        if (inputType == "FORMULA") {
          this.fullscreenDialog = true;
        }

        if (
          inputType == "FORMULA" ||
          inputType == "ENTITY_VARIABLE" ||
          inputType == "ACTION_BUTTON"
        ) {
          let modifiedData = JSON.parse(JSON.stringify(this.fieldsData));
          this.fieldsData = this.getFieldsFromElements(modifiedData);
        }
        field.is_fixed = timeFixed;

        let reachedIndex = this.checkTableFields(field);

        if (reachedIndex > -1) {
          field.data_table_field_index = reachedIndex;
          if (
            field.type == "CONTENT" &&
            field.input_type != "IMAGE" &&
            field.input_type != "ICON" &&
            field.input_type != "ACTION_BUTTON" &&
            field.input_type != "PARAGRAPH"
          ) {
            this.$message({
              message: "Content Type Elements Not Allowed in Data Table",
              type: "warning",
            });
            field = {};
            return;
          } else if (field.inputType == "AUTO_INCREMENT_NUMBER") {
            this.$message({
              message: "Auto Increment Field Not Allowed in Data Table",
              type: "warning",
            });
            field = {};
            return;
          }
        }
        //set deafault values for field
        if (inputType == "ACTION_BUTTON") {
          field = {
            ...field,
            ...{
              action_button_type: "BUTTON",
              action_button_action_type: "TEMPLATE_ACTION",
              action_button_target_location: "NEW_TAB",
            },
          };
        }
        this.startAddNewField(field);
      }
      let addFieldToHistory = {
        data: this.editableField,
        action: "add",
        index: this.fieldsData.length,
      };
      this.history.push(addFieldToHistory);
      this.history.sort((a, b) => {
        return a.data.y - b.data.y;
      });
      if (this.history.length > 0) {
        if (
          this.history[this.history.length - 1].data.y +
            this.history[this.history.length - 1].data.height <
          5000
        ) {
          this.formHeight = 5000;
        } else {
          this.formHeight = this.history[this.history.length - 1].data.y + 500;
        }
      }
    },
    displayPrimaryFieldDialogClose() {
      this.displayPrimaryFieldDialog = false;
    },
    getDimentions(inputType) {
      let fieldsDimentions = this.fieldActionsObject[inputType];

      fieldsDimentions.x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      fieldsDimentions.y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        fieldsDimentions.height;
      return fieldsDimentions;
    },

    validExpression(field) {
      let exp = "";
      let lastField = "";
      let err = false;

      if (
        field &&
        field.selected_fields &&
        field.selected_fields[0] &&
        (field.selected_fields[0].input_type === "DATE_TIME_RANGE" ||
          field.selected_fields[0].input_type === "DATE_RANGE")
      ) {
        return true;
      } else {
        let selectedFieldsLength = field.selected_fields.length;
        if (selectedFieldsLength && selectedFieldsLength > 2) {
          field.selected_fields.map((el) => {
            if (el.type == "OPERATOR") {
              exp += el.operator + " ";
              if (lastField != "operator") {
                lastField = "operator";
              } else {
                err = true;
              }
            } else if (el.type == "PARENTHESES") {
              exp += el.parentheses + " ";
              lastField = "";
            } else {
              exp += Math.floor(Math.random() * Math.floor(9)) + " ";
              if (lastField != "number") {
                lastField = "number";
              } else {
                err = true;
              }
            }
            return;
          });
          try {
            if (err) {
              return false;
            } else {
              mexp.eval(exp);
              return true;
            }
          } catch (e) {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    disableFieldButton(field) {
      if (
        [
          "PARAGRAPH",
          "SINGLE_LINE_CONTENT",
          "HORIZONTAL_LINE",
          "CAROUSEL",
        ].indexOf(field.input_type) > -1
      ) {
        if (field && field.input_type == "CAROUSEL" && field.carousel_cards) {
          return !field.carousel_cards.length > 0;
        }
        return !field.content;
      }
      if (field.input_type === "MULTI_LINE_TEXT") {
        if (!field.validations.rows || field.validations.rows < 3) {
          return true;
        }
        return !field.label;
      }
      if (field.input_type === "CALENDER") {
        return (
          !field.label ||
          !field.selectedEntities ||
          !field.selectedEntities.length
        );
      }

      if (
        field.input_type === "SELECT" ||
        field.input_type === "MULTI_SELECT"
      ) {
        if (field.is_global_variable) {
          return !field.label || !field.global_variable_id;
        } else {
          return (
            (field.options &&
              field.options.length == "0" &&
              !field.allow_to_create) ||
            (field.options.some((opt) => opt.trim() === "") &&
              !field.allow_to_create) ||
            !field.label
          );
        }
      }
      if (field.input_type === "HTML_CONTENT") {
        return !field.content;
      }

      if (
        field.input_type === "CHECKBOX_GROUP" ||
        field.input_type === "RADIO_BUTTON" ||
        field.input_type === "RADIO_BUTTON_GROUP"
      ) {
        if (field.is_global_variable) {
          return !field.label;
        } else {
          return (
            (field.options && field.options.length == "0") ||
            field.options.some((opt) => opt.trim() === "") ||
            !field.label
          );
        }
      }
      if (field.input_type == "AGGREGATE_FUNCTION") {
        if (
          !(
            field.fields_type &&
            field.function_type &&
            field.repeatable_template
          ) ||
          (field.aggregate_self_template && !field.selected_fields?.length) ||
          (!field.aggregate_self_template && !field.aggregation_field)
        ) {
          return true;
        }
      }

      if (field.input_type === "CURRENCY") {
        {
          if (!field.label) {
            return true;
          }
        }
        return !field.validations.currency;
      }
      if (field.input_type === "MULTI_LINE_TEXT") {
        if (field.validations.rows < 3) {
          return false;
        }

        return !field.validations.rows || !field.label;
      }
      if (field.input_type === "IMAGE") {
        return !field.label;
      }
      if (field.input_type === "AUTHORIZED_SIGNATURE") {
        return !field.authorized_signature;
      }
      if (field.input_type === "VIDEO") {
        return !field.fileSize || field.fileSize > 5000;
      }
      if (field.input_type === "FORMULA") {
        let hasCurrencyField = false;
        let hasDateField = false;
        let hasTimeField = false;

        for (const selectedField of field.selected_fields) {
          if (selectedField.input_type === "CURRENCY") {
            hasCurrencyField = true;
          } else if (selectedField.input_type === "DATE") {
            hasDateField = true;
          } else if (selectedField.input_type === "TIME") {
            hasTimeField = true;
          }
        }
        return (
          !this.validExpression(field) ||
          (!field.time_result_type && hasTimeField) ||
          (!field.validations.currency && hasCurrencyField) ||
          (!field.date_result_type && hasDateField) ||
          !field.label
        );
      }
      if (field.input_type === "AUTO_INCREMENT_NUMBER") {
        return (
          (!field.auto_increment_prefix &&
            !field.auto_increment_starting_number) ||
          !field.label
        );
      }
      if (field.input_type === "PAY_BUTTON") {
        return (
          (field.validations.paymentSystems &&
            !field.validations.paymentSystems.length) ||
          (field.validations.payModes && !field.validations.payModes.length) ||
          field.validations.currency == undefined ||
          field.validations.entityVariable_info == undefined
        );
      }
      if (
        this.field &&
        this.field.inputType == "ACTION_BUTTON" &&
        this.field.is_global_variable
      ) {
        return true;
      }
      if (field.input_type === "ACTION_BUTTON") {
        if (field.label) {
          if (field?.actions && field.actions.length) {
            let emptyField = field.actions.find((action) => {
              if (!action.action_button_action_type) {
                return true;
              }
              if (action.action_button_action_type == "NAVIGATION") {
                if (
                  !action.action_button_action_navigation_type ||
                  !action.action_button_target_location
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type == "OPEN_URL" &&
                  (!action.action_button_target_link ||
                    !this.isValidURL(action.action_button_target_link))
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_DOCUMENT_TEMPLATE_BUILDER" &&
                  !action.action_button_document_template_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_FORM_BUILDER" &&
                  !action.action_button_formbuilder_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_ENTITY" &&
                  !action.action_button_entity_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_ENTITY_VIEW" &&
                  !action.action_button_entity_view_id
                ) {
                  return true;
                }
                return false;
              } else if (
                action.action_button_action_type == "TEMPLATE_ACTION"
              ) {
                return false;
              } else if (
                action.action_button_action_type == "ESTABLISH_RELATIONSHIP"
              ) {
                if (!action.relational_entity_one) {
                  return true;
                }
                return false;
              } else if (
                action.action_button_action_type == "CREATE_DUPLICATE"
              ) {
                if (!action.selected_fields?.length) {
                  return true;
                }
              }
            });
            if (emptyField) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        }
        // if (
        //   field.label &&
        //   field.action_button_type &&
        //   field.action_button_action_type
        // ) {
        //   if (
        //     field.action_button_action_type == "OPEN_URL" &&
        //     field.action_button_target_location &&
        //     field.action_button_target_link &&
        //     this.isValidURL(field.action_button_target_link)
        //   ) {
        //     return false;
        //   } else if (
        //     field.action_button_action_type == "OPEN_FORM_BUILDER" &&
        //     field.action_button_formbuilder_id
        //   ) {
        //     return false;
        //   } else if (
        //     field.action_button_action_type ==
        //       "OPEN_DOCUMENT_TEMPLATE_BUILDER" &&
        //     field.action_button_document_template_id
        //   ) {
        //     return false;
        //   } else if (field.action_button_action_type == "TEMPLATE_ACTION") {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // } else {
        //   return true;
        // }
      }
      if (field.input_type === "GLOBAL_VARIABLE") {
        if (field.label && field.global_variable_type) {
          if (
            field.global_variable_type == "ENTITY" &&
            field.global_variable_entity_type &&
            field.global_variable_entity_select_type &&
            (field.allow_new_entity_data || !field.allow_new_entity_data)
          ) {
            return false;
          } else if (
            field.global_variable_type == "USERS" &&
            field.global_variable_user_type
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (field.input_type === "ENTITY") {
        let allConfiguredFilter = true;
        if (field.filters && field.filters.length) {
          let nonConfiguredFilter = field.filters.find(
            (e) =>
              !e.field ||
              (e.field == "RESOURCE_MANAGEMENT" &&
                (!e.resource_count ||
                  !e.resource_source ||
                  !e.data_source ||
                  (!e.resource_value_field && !e.resource_value) ||
                  (!e.value && !e.value_field))) ||
              (e.field == "UNIQUE" &&
                (!e.data_source || (!e.value && !e.value_field))) ||
              (e.field != "UNIQUE" &&
                e.field != "RESOURCE_MANAGEMENT" &&
                (!e.operator ||
                  (!e.value &&
                    !e.value_field &&
                    typeof e.value !== "number" &&
                    e.data_source != "self_field" &&
                    e.data_source != "from_date" &&
                    e.operator != "exists" &&
                    e.operator != "notexists" &&
                    e.data_source != "GLOBAL_VARIABLE" &&
                    e.operator != "weekdays") ||
                  (!e.data_source &&
                    e.operator != "exists" &&
                    e.operator != "notexists" &&
                    e.operator != "between" &&
                    e.operator != "real_time" &&
                    e.operator != "weekdays")))
          );
          if (
            nonConfiguredFilter &&
            (nonConfiguredFilter.field == "self#related_to/parent" ||
              nonConfiguredFilter.field == "self#related_to/self") &&
            nonConfiguredFilter.operator !== ""
          ) {
            nonConfiguredFilter = undefined;
          }
          allConfiguredFilter = nonConfiguredFilter ? false : true;
        }
        return (
          !field.label ||
          !field.entity_id ||
          !allConfiguredFilter ||
          !field.primary_fields ||
          !field.primary_fields.length
        );
      }
      if (field.input_type === "ENTITY_VARIABLE") {
        let allowedTypes = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
        let parent = (this.fieldsData || []).find(
          (e) => e.key == field.relationship_key
        );
        if (
          parent?.allow_multiple &&
          (allowedTypes.indexOf(field.inputType) != -1 ||
            (field.inputType == "FORMULA" &&
              allowedTypes.indexOf(field.result_type) != -1))
        ) {
          return !field.variable_action;
        }
        return (
          !field.label ||
          !field.relationship_key ||
          !field.global_variable_entity_field
        );
      }
      if (field.input_type === "QUESTION") {
        return !field.question_text || !field.question_type;
      }
      if (field.input_type === "RANDOM_TEXT") {
        return !field.selected_preferences?.length;
      }
      if (field.input_type === "INTEGRATION") {
        if (!field.integration_settings.selectFromTemplate) {
          return (
            !field.integration_settings.selectedApplication ||
            !field.integration_settings.selectedEvent ||
            !field.integration_settings.selectedIntegration ||
            !field.integration_settings.selectedColumnLabel ||
            !field.integration_settings.selectedColumnKey ||
            !this.validateRequiredFields(
              field.integration_settings.requiredFields
            )
          );
        } else if (field.integration_settings.selectFromTemplate) {
          return (
            !field.integration_settings.selectedApplication ||
            !field.integration_settings.selectedEvent ||
            !field.integration_settings.templateDependencies ||
            !field.integration_settings.selectedColumnLabel ||
            !field.integration_settings.selectedColumnKey
          );
        }
      }
      return !field.label;
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    // async saveTemplate() {
    //   if (!this.name) {
    //     this.dialogTemplatePrimaryData = true;
    //   } else {
    //     this.loading = true;
    //     // calling store
    //     try {
    //       if (this.templateReatingStatus && !this.primaryField.length) {
    //         this.displayPrimaryFieldDialog = true;
    //       } else {
    //         this.submitTemplateData();
    //       }
    //     } catch (err) {
    //       this.$notify.error({
    //         title: "Error",
    //         message:
    //           this.getTemplateErrors && this.getTemplateErrors.name
    //             ? this.getTemplateErrors.name
    //             : "Error while saving template 3",
    //       });
    //     }
    //     setTimeout(() => {
    //       this.loading = false;
    //       this.loadingText = "";
    //     }, 1500);
    //   }
    // },
    async saveTemplate() {
      if (this.fieldsData) {
        this.dateFields = this.fieldsData.filter(
          (field) => field.input_type == "DATE" && field.inputType == "DATE"
        );
        this.show = this.dateFields.filter(
          (field) =>
            field.properties.show_weekdays === true ||
            field.properties.date_settings == "WeekDays"
        );
        const empty = this.show.some(
          (field) =>
            field?.properties?.selected_weekdays?.length === 0 &&
            !field?.properties?.selected_weekdays_mapped_field
        );
        if (empty) {
          const fieldNames = this.show.map((field) => field.key).join(", ");
          this.$notify.error({
            title: "Error",
            message: `Please select at least one weekday in field properties for ${fieldNames} field`,
          });
          return;
        }
      }
      if (!this.name) {
        this.dialogTemplatePrimaryData = true;
        return; // Exit the method if name is not provided
      }

      this.loading = true;
      try {
        if (this.templateReatingStatus && !this.primaryField.length) {
          this.displayPrimaryFieldDialog = true;
        } else {
          await this.submitTemplateData(); // Assuming submitTemplateData is an asynchronous function
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message:
            this.getTemplateErrors && this.getTemplateErrors.name
              ? this.getTemplateErrors.name
              : "Error while saving template 3",
        });
      } finally {
        setTimeout(() => {
          this.loading = false;
          this.loadingText = "";
        }, 1500);
      }
    },

    async submitTemplateData() {
      let query = this.getNavigationQuery(this.$route.query);
      this.loading = true;
      this.loadingText = "Please wait ...";
      let payload =
        this.type == "STANDARD"
          ? this.prepareStandardTemplateData()
          : this.prepareFinalSavingData();
      let fieldInfo = this.fieldsData.find(
        (field) => field.key == this.primaryField
      );
      payload.primary_fields.push(fieldInfo);
      if (this.displayPrimaryFieldDialog) {
        this.displayPrimaryFieldDialog = false;
      }
      payload.templateGroups = this.$route.query.group
        ? [this.$route.query.group]
        : this.templateGroups;
      if (this.selectedTemplateId) {
        payload._id = this.selectedTemplateId;
        payload.updated_record = true;
        this.loadingText = "Updating Template...";
        try {
          await postAPICall("PUT", "/template", payload);
          this.$notify.success({
            title: "Success",
            message: "Template updated successfully",
          });
          await updateTemplateData(this.selectedTemplateId, {
            ...this.templateData,
            ...payload,
          });
          this.$router.push({ path: "/template", query });
        } catch (e) {
          let getTemplateErrors = e.response;
          this.$notify.error({
            title: "Error",
            message:
              getTemplateErrors &&
              (getTemplateErrors.name || getTemplateErrors.critical_error)
                ? getTemplateErrors.name || getTemplateErrors.critical_error
                : "Error while saving template",
          });
        }
      } else {
        this.loadingText = "Adding Template...";
        try {
          const getTemplateAddData = await postAPICall(
            "POST",
            "/template/create",
            payload
          );
          if (getTemplateAddData?.data) {
            addSingleTemplateToLocal(getTemplateAddData.data);
          }
          this.$notify.success({
            title: "Success",
            message: "Template Added successfully",
          });
          this.$router.push({ path: "/template", query });
        } catch (e) {
          let getTemplateErrors = e.response;
          this.$notify.error({
            title: "Error",
            message:
              getTemplateErrors &&
              (getTemplateErrors.name || getTemplateErrors.critical_error)
                ? getTemplateErrors.name || getTemplateErrors.critical_error
                : "Error while saving template 2",
          });
        }
      }
      setTimeout(() => {
        this.loading = false;
        this.loadingText = "";
      }, 1500);
    },

    async checkEntitiesFields() {
      try {
        let fields = this.getTemplateUpdateData.sections[0].fields;
        this.entityFields = fields.filter((e) => e.inputType == "ENTITY");

        if (this.entityFields.length) {
          await this.$store.dispatch("entities/getEntitiesByTemplates", {
            template_id: this.selectedTemplateId,
          });

          let entities = [...this.getTemplateRelatedEntities];

          this.templateRelatedEntities = entities;
          this.showTemplateFieldsRelationshipData = true;
        } else {
          this.$router.push({ path: "/template" });
        }
      } catch (err) {
        console.error("checkEntitiesFields", err);
      }
    },

    closeEntityRelationshipDialog() {
      this.showTemplateFieldsRelationshipData = false;
      this.$router.push({ path: "/template" });
    },

    prepareStandardTemplateData() {
      return {
        slug: this.slug,
        name: this.name,
        type: this.type,
        predefined_template_id: this.$route.query.default_template_id,
      };
    },

    previewTemplate() {
      this.previewDialogVisible = true;
    },
    editExistedField(item, index) {
      this.activatedItem = item;
      this.currentActiveIndex = index;

      if (
        this.fieldsData[index].inputType == "FORMULA" ||
        this.fieldsData[index].input_type == "FORMULA" ||
        this.fieldsData[index].input_type == "PAYMENT_VARIABLE"
      ) {
        this.fullscreenDialog = true;
      }
      this.editableFieldIndex = index;

      this.editableField = JSON.parse(JSON.stringify(this.fieldsData[index]));
      let dimentions =
        this.getDimentions(this.fieldsData[index].inputType) ||
        this.getDimentions(this.fieldsData[index].input_type);

      this.editableField.dialog_fullscreen = dimentions.dialog_fullscreen;

      this.dialogFormVisible = true;
    },

    editExistedTableField(item, index) {
      if (item.inputType == "FORMULA" || item.input_type == "FORMULA") {
        this.fullscreenDialog = true;
      }
      this.editableFieldIndex = index;

      this.editableField = JSON.parse(JSON.stringify(item));
      this.editableField["data_table_field_index"] =
        this.selectedDataTableIndex;
      let dimentions =
        this.getDimentions(item.inputType) ||
        this.getDimentions(item.input_type);

      this.editableField.dialog_fullscreen = dimentions.dialog_fullscreen;
      this.dialogFormVisible = true;
    },

    checkTableFields(dimentions) {
      dimentions.x = dimentions.x + dimentions.width / 2;
      let y = dimentions.y + dimentions.height / 2;
      return this.fieldsData.findIndex((field) => {
        if (
          field.input_type == "DATA_TABLE" &&
          dimentions.x >= field.x &&
          dimentions.x <= field.x + field.width &&
          y >= field.y &&
          y <= field.y + field.height
        ) {
          return true;
        }
        if (
          field.input_type == "DIVISION" &&
          dimentions.x >= field.x &&
          dimentions.x <= field.x + field.width &&
          y >= field.y &&
          y <= field.y + field.height
        ) {
          return true;
        }
      });
    },
    startAddNewField(field) {
      if (
        field.inputType == "FORMULA" ||
        field.inputType == "AGGREGATE_FUNCTION"
      ) {
        this.prepareKeysForNumbers();
      }
      this.editableField = JSON.parse(JSON.stringify(field));
      this.dialogFormVisible = true;
    },
    prepareKeysForNumbers() {
      this.fieldsData = this.getFieldsFromElements(this.fieldsData);
      this.fieldsData = this.prepareFieldKeysForDataTableFields(
        this.fieldsData,
        true
      );
    },
    async doneEditField() {
      let updatelistField = (this.editableField.list_data || []).filter(
        (e) => (e && e.name == "") || e.value == ""
      );
      if (
        this.editableField &&
        (this.editableField.inputType == "HEADING" ||
          this.editableField.input_type == "HEADING") &&
        !this.editableField.heading_type
      ) {
        this.$message.error("Please select heading type.!");
      } else if (
        this.editableField.inputType == "CHECKBOX_GROUP" &&
        this.editableField.min_selection > this.editableField.max_selection
      ) {
        this.$message.error(
          "The minimum value cannot exceed the maximum value.!"
        );
      } else if (
        this.editableField.inputType === "MULTI_SELECT" &&
        this.editableField.min_selection > this.editableField.max_selection
      ) {
        this.$message.error(
          "The minimum value cannot exceed the maximum value!"
        );
      } else if (
        this.editableField.inputType == "CHECKBOX_GROUP" &&
        !this.editableField.is_global_variable &&
        this.editableField.input_type != "ENTITY_VARIABLE" &&
        (this.editableField.min_selection == undefined ||
          this.editableField.max_selection == undefined)
      ) {
        this.$message.error("please enter minimum and maximum value.!");
      } else if (
        (this.editableField.inputType == "CHECKBOX_GROUP") &
          this.editableField.options &&
        this.editableField.options.length < this.editableField.min_selection
      ) {
        this.$message.error(
          "Field options should be greater than or equal to the 'Min selection'."
        );
      } else if (
        this.editableField.input_type == "CHECKBOX" &&
        this.editableField.group_key &&
        this.checkBoxCurrentObj.min_value > this.checkBoxCurrentObj.max_value
      ) {
        this.$message.error(
          "The minimum value cannot exceed the maximum value.!"
        );
      } else if (
        this.editableField.inputType == "FILE" &&
        this.editableField.validations.min_document_files >
          this.editableField.validations.max_document_files
      ) {
        this.$message.error(
          "The minimum value cannot exceed the   maximum value.!"
        );
      } else if (
        this.editableField.inputType == "FILE" &&
        this.editableField.validations.multiple &&
        (this.editableField.validations.min_document_files == undefined ||
          this.editableField.validations.max_document_files == undefined)
      ) {
        this.$message.error("please enter minimum and maximum value.!");
      } else if (
        this.editableField.inputType == "IMAGE" &&
        this.editableField.validations.min_image_files >
          this.editableField.validations.max_image_files
      ) {
        this.$message.error(
          "The minimum value cannot exceed the   maximum value.!"
        );
      } else if (
        this.editableField.inputType == "IMAGE" &&
        this.editableField.validations.multiple &&
        (this.editableField.validations.min_image_files == undefined ||
          this.editableField.validations.max_image_files == undefined)
      ) {
        this.$message.error("please enter minimum and maximum value.!");
      }
      //else if (
      //  this.editableField.inputType == "AUTO_INCREMENT_NUMBER" &&
      //  !this.editableField.auto_increment_prefix
      //) {
      //  this.$message.error("please enter prefix value.!");
      //}
      else if (
        this.editableField.input_type == "AUTO_INCREMENT_NUMBER" &&
        !this.editableField.auto_increment_starting_number
      ) {
        this.$message.error("please enter starting value.!");
      } else {
        if (
          this.editableField &&
          this.editableField.name == "Multiple Select" &&
          this.editableField.options &&
          !this.editableField.options.length &&
          !this.editableField.allow_to_create &&
          !this.editableField.is_global_variable
        ) {
          this.$message.warning("Please add options!");
        } else if (
          this.editableField &&
          this.editableField.input_type == "RADIO_BUTTON_GROUP" &&
          this.editableField.options &&
          !this.editableField.options.length
        ) {
          this.$message.warning("Please add options!");
        } else if (
          this.editableField &&
          this.editableField.input_type == "LIST" &&
          this.editableField.list_data &&
          !this.editableField.list_data.length
        ) {
          this.$message({
            message: "Please add list items data",
            type: "warning",
          });
        } else if (
          this.editableField &&
          this.editableField.input_type == "ENTITY" &&
          this.editableField.show_count &&
          (this.editableField.show_count_type == "sum" ||
            this.editableField.show_count_type == "average") &&
          this.editableField.count_field.length == "0"
        ) {
          this.$message({
            message: "Please Select Number field in advanced options",
            type: "error",
          });
        } else if (
          this.editableField &&
          this.editableField.input_type == "CONCATENATE" &&
          this.editableField.selected_fields &&
          !this.editableField.selected_fields.length
        ) {
          this.$message({
            message: "Please  select and add field",
            type: "warning",
          });
        } else if (
          updatelistField &&
          updatelistField.length &&
          updatelistField.length > 0
        ) {
          this.$message.error("Name and value cannot be empty!");
        } else {
          if (
            this.editableField.input_type == "AGGREGATE_FUNCTION" &&
            this.editableField.aggregate_self_template
          ) {
            delete this.editableField.repeatable_template;
          }
          if (
            this.editableField.input_type == "QUESTION" &&
            this.editableField.question_type == "MCQ"
          ) {
            if (this.editableField.question_options_format == "VERTICAL_LIST") {
              this.editableField.height =
                100 +
                (this.editableField.options && this.editableField.options.length
                  ? this.editableField.options.length * 50
                  : 200);
            } else {
              this.editableField.height = 150;
            }
          }
          if (
            this.editableField.type == "CHECKBOX" &&
            this.editableField.group_key
          ) {
            let gIndex = -1;
            gIndex = this.checkBoxGroupArray.findIndex(
              (g) => g.key == this.editableField.group_key
            );
            //this.current_group_index = gIndex;
            this.checkBoxGroupArray[gIndex] = this.checkBoxCurrentObj;
          }
          if (this.editableField.input_type == "FORMULA") {
            this.editableField.result_type = this.getResultType(
              this.editableField.selected_fields
            );
          }
          if (this.editableField.validations) {
            this.editableField.validations = {
              ...this.editableField.validations,
              ...{
                data_type: this.getTypeFromInputType(
                  this.editableField.input_type
                ),
              },
            };
          } else {
            this.$set(this.editableField, "validations", {
              data_type: this.getTypeFromInputType(
                this.editableField.input_type
              ),
            });
          }
          let fieldExists = false;

          // Check if the editable field exists in the fieldsData
          for (let i = 0; i < this.fieldsData.length; i++) {
            if (this.fieldsData[i].key === this.editableField.key) {
              // Update the existing field
              this.fieldsData[i] = {
                ...this.fieldsData[i],
                ...this.editableField,
              };
              fieldExists = true;
              break;
            }
          }
          if (!fieldExists) {
            if (
              this.editableFieldIndex === -1 ||
              this.editableField.data_table_field_index == undefined
            ) {
              if (
                this.editableField.data_table_field_index > -1 &&
                this.fieldsData[this.editableField.data_table_field_index]
                  .inputType == "DATA_TABLE"
              ) {
                if (!this.editableField.styles) {
                  this.editableField.styles = {
                    styles: "no",
                  };
                }
                if (
                  this.fieldsData[this.editableField.data_table_field_index]
                    .data_table_columns &&
                  this.fieldsData[this.editableField.data_table_field_index]
                    .data_table_columns.length
                ) {
                  this.fieldsData[
                    this.editableField.data_table_field_index
                  ].data_table_columns.push(this.editableField);
                } else {
                  this.fieldsData[
                    this.editableField.data_table_field_index
                  ].data_table_columns = [this.editableField];
                }

                this.reRenderDataTable = true;
                setTimeout(() => {
                  this.reRenderDataTable = false;
                });
              } else {
                if (
                  this.editableField?.data_table_field_index != undefined &&
                  this.fieldsData[this.editableField.data_table_field_index]
                    .inputType == "DIVISION"
                ) {
                  this.editableField.is_in_division =
                    this.fieldsData[
                      this.editableField.data_table_field_index
                    ].key;
                  this.fieldsData.push(this.editableField);
                } else {
                  this.fieldsData.push(this.editableField);
                }
              }
            } else {
              if (
                this.selectedDataTableIndex > -1 &&
                this.fieldsData[this.selectedDataTableIndex]
              ) {
                this.fieldsData[this.selectedDataTableIndex].data_table_columns[
                  this.editableFieldIndex
                ] = this.editableField;

                // we need to rerender table

                this.reRenderDataTable = true;
                setTimeout(() => {
                  this.reRenderDataTable = false;
                });
                this.selectedDataTableFields = [];
              } else {
                this.fieldsData[this.editableFieldIndex] = this.editableField;
              }
            }
          }
          if (
            this.editableField.input_type === "FILE" &&
            this.uploadingSenderFiles &&
            this.uploadingSenderFiles.length
          ) {
            await this.uploadFile(this.uploadingSenderFiles);
          }
          let field = JSON.parse(JSON.stringify(this.editableField));
          let fields = [];
          await setTimeout(() => {
            fields = this.fieldsData;
            this.fieldsData = [];
            this.fieldsData = [...fields];
            this.fieldsData = fields;
            this.refreshFields = false;
          }, 1000);
          this.isSelectView = true;
          this.prepareKeysForNumbers();
          if (field.input_type == "ENTITY_TABLE") {
            let index =
              this.editableFieldIndex > -1
                ? this.editableFieldIndex
                : this.fieldsData.length - 1;
            this.fieldsData[index].data_table_columns.map((col) => {
              col["data_table_key"] = this.fieldsData[index].key;
              col["data_table_field_index"] = index;
              // col["key"] = col["key"] + "_" + index;
            });
          }
          this.fullscreenDialog = false;
          this.editableFieldIndex = -1;
          this.selectedDataTableIndex = -1;
          this.selectedDataTableFields = [];
          this.editableField = {};
          this.dialogFormVisible = false;
        }
      }
    },
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(filesData);
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );
      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        //  else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    getResultType(fields = []) {
      let type = "NUMBER";
      if (fields.length) {
        let timeFields = fields.filter(
          (e) =>
            e.input_type == "TIME" ||
            (e.type == "FORMULA" && e.result_type == "TIME") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "TIME")
        );
        let dateFields = fields.filter(
          (e) =>
            e.input_type == "DATE" ||
            e.input_type == "DATE_TIME" ||
            (e.type == "FORMULA" && e.result_type == "DATE") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "DATE")
        );
        let listFields = fields.filter((e) => e.input_type == "LIST");
        let numberFields = fields.filter(
          (e) =>
            e.type == "NUMBER" ||
            (e.type == "FORMULA" && e.result_type == "NUMBER") ||
            e.input_type == "NUMBER" ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "NUMBER")
        );
        let currencyField = fields.filter(
          (e) =>
            e.input_type == "CURRENCY" ||
            ((e.type == "FORMULA" || e.input_type == "AGGREGATE_FUNCTION") &&
              e.result_type == "CURRENCY") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "CURRENCY")
        );
        if (currencyField && currencyField.length) {
          type = "CURRENCY";
        } else if (
          ((numberFields && numberFields.length) ||
            (listFields && listFields.length)) &&
          dateFields &&
          dateFields.length == 1
        ) {
          type = "DATE";
        } else if (timeFields && timeFields.length == 1) {
          type = "TIME";
        }
      }
      return type;
    },
    canelEditField() {
      this.selectedDataTableIndex = -1;
      this.editableFieldIndex = -1;
      this.editableField = {};
      this.dialogFormVisible = false;
      this.fullscreenDialog = false;
    },
    defaultFieldData() {
      return {
        label: "",
        key: "",
        input_type: "",
        inputType: "",
        content: "",
        selected_fields: [],
        formula: "",
        function_type: "",
        validations: {
          required: false,
          multiple: false,
          maxFiles: 1,
          minFiles: 1,
          max_document_files: 1,
          min_document_files: 1,
          min_image_files: 1,
          max_image_files: 1,
          vertical_line: false,
          paymentSystems: [],
          payAmount: 0,
          updateRules: {
            success: [],
            failure: [],
          },
          partialPayment: false,
          transactionsCal: true,
          transactionType: "NONE",
          qr_data_field: "",
          qrType: "Record Id",
        },
        ai_settings: {
          isAIEnabled: false,
          selectedOperation: null,
          selectedSourceField: null,
          selectedAdditionalFields: [],
          selectedStyle: null,
          runOnAction: [],
          realtimeButtonContent: "",
          userPrompt: "",
        },
        integration_settings: {
          selectedIntegration: "",
          selectedApplication: "",
          selectedEvent: "",
          selectedColumnLabel: "",
          selectedColumnKey: "",
          requiredFields: {},
          selectFromTemplate: false,
          templateDependencies: [],
        },
        overwrite_on_change: true,
        phoneNo: "",
        defaultCountry: "",
        countryCallingCode: "",
        countryCodeSelector: true,
        countryDisable: false,
        options: [],
        styles: {},
        properties: {
          is_unique: false,
          personal_data: false,
          personal_data_type: "",
          is_encrypted: false,
          time_range: [
            new Date(2016, 9, 10, 0, 0),
            new Date(2016, 9, 10, 23, 59, 59),
          ],
          step_time: "30",
          tableDefaultValue: "",
          allow_to_regenerate: false,
        },
        decimals: "",
        date_result_type: "",
        date_view_type: "day",
        active: false,
        data_table_filter_query: "AND",
        data_table_filters: [],
        data_table_field_auto_fill_type: "range",
        data_table_field_auto_fill: false,
        child_table_auto_fill: false,
        child_table_type: "interactive",
        always_execute: false,
        selected_auto_fill_fields: {
          from: "",
          to: "",
          range: "",
          type: "days",
          frequency_type: "days",
          frequency_step: 1,
        },
        selected_auto_fill_entity: "",
        default_rows: 0,
        random_text_length: 6,
        selected_preferences: ["Use Letters", "Use Numbers"],
        search_mode_enabled: false,
        fileSize: 10,
        add_through_excel: false,
      };
    },
    onActivated(item, index) {
      this.checkBoxCurrentObj = {};
      this.activatedItem = item;
      this.activatedItemIndex = index;
      this.showAlignLines(this.activatedItem.x, this.activatedItem.y, false);
      this.activatedItem.active = true;
      item.active = true;
      this.isFromFieldActivated = true;
      this.hideAllAlignLinesWithDelay();
      if (
        this.activatedItem.input_type == "CHECKBOX" &&
        this.activatedItem.group_key
      ) {
        let gIndex = -1;
        gIndex = this.checkBoxGroupArray.findIndex(
          (g) => g.key == this.activatedItem.group_key
        );
        this.current_group_index = gIndex;
        this.checkBoxCurrentObj = this.checkBoxGroupArray[gIndex];
      }
    },
    async checkConditionsAndActions() {
      const targetKey = this.activatedItem?.key || this.editableField?.key;

      const hasConditionOrAction = this.templateRules.map((rule) => {
        const { conditions, actions } = rule;

        // Check conditions for both normal fields and data table fields
        const hasCondition = conditions.some((condition) => {
          const isNormalField = condition.selectField === targetKey;
          const isDataTableField =
            condition.selectField.includes("data_table") &&
            condition.selectField.split("#")[1] === targetKey;

          return isNormalField || isDataTableField;
        });
        const hasAction = actions.some((action) => {
          const isNormalField = action.selectField === targetKey;
          const isDataTableField =
            action.selectField.includes("data_table") &&
            action.selectField.split("#")[1] === targetKey;

          return isNormalField || isDataTableField;
        });

        return hasCondition || hasAction;
      });
      return hasConditionOrAction.every((has) => !has);
    },

    async deleteElement() {
      const canDelete = await this.checkConditionsAndActions();
      if (!canDelete) {
        this.$message({
          message: "Can't delete, This field is used in Form rules.",
          type: "warning",
        });
        return;
      }
      if (this.selectedDataTableIndex > -1) {
        this.fieldsData[this.selectedDataTableIndex].data_table_columns.splice(
          this.editableFieldIndex,
          1
        );

        this.editableFieldIndex = -1;
        this.editableField = {};
        this.dialogFormVisible = false;
        this.selectedDataTableIndex = -1;
        this.selectedDataTableFields = [];
        this.reRenderDataTable = true;
        setTimeout(() => {
          this.reRenderDataTable = false;
        });

        return;
      }

      let existedOnDuplicateRules = this.existedOnDuplicateRules(
        this.editableField.key
      );
      if (existedOnDuplicateRules) {
        this.$message.warning(
          "Can't delete, This field is used in duplicate rules."
        );
        return;
      }
      // let formulas = this.lodash.filter(this.fieldsData, function (element) {
      //   return element.input_type == "FORMULA";
      // });
      let formulas = this.fieldsData.filter(
        (element) => element.input_type === "FORMULA"
      );
      if (formulas && formulas.length) {
        let selectedFields = [];
        await formulas.forEach((formulaField) => {
          formulaField.selected_fields
            ? selectedFields.push(...formulaField.selected_fields)
            : "";
        });

        this.fieldsData.splice(this.currentActiveIndex, 1);
        this.editableFieldIndex = -1;
        this.editableField = {};
        this.dialogFormVisible = false;
      } else {
        // let formulas = this.lodash.filter(this.fieldsData, function (element) {
        //   return element.input_type == "FORMULA";
        // });
        let formulas = this.fieldsData.filter(
          (element) => element.input_type === "FORMULA"
        );
        if (formulas && formulas.length) {
          let selectedFields = [];
          await formulas.forEach((formulaField) => {
            formulaField.selected_fields
              ? selectedFields.push(...formulaField.selected_fields)
              : "";
          });
          // let isExits = this.lodash.findIndex(selectedFields, {
          //   key: this.activatedItem.key,
          // });
          let isExits = selectedFields.findIndex(
            (item) => item.key === this.activatedItem.key
          );
          if (isExits > -1) {
            this.$message.warning("This field is used in other areas.");
            this.dialogFormVisible = false;
          } else {
            this.fieldsData.splice(this.currentActiveIndex, 1);
            this.editableFieldIndex = -1;
            this.editableField = {};
            this.dialogFormVisible = false;
          }
        } else {
          if (this.editableField.inputType === "DIVISION") {
            // Loop through each field in fieldsData to check if it's inside the division
            this.fieldsData.forEach((field) => {
              if (field.is_in_division == this.editableField.key) {
                field.is_in_division = "";
              }
            });
          }

          // Now handle the deletion of the editable field, whether it's a DIVISION or not
          let addFieldToHistory = {
            action: "delete",
            data: this.editableField,
            index: this.currentActiveIndex,
          };
          this.history.push(addFieldToHistory);

          // Remove the editable field from fieldsData
          this.fieldsData.splice(this.currentActiveIndex, 1);

          // Reset the editable field and close the dialog
          this.editableFieldIndex = -1;
          this.editableField = {};
          this.dialogFormVisible = false;
        }
      }
    },
    onDeactivated(item) {
      this.activatedItem = {};
      this.activatedItemIndex = -1;
      this.activatedItem.active = false;
      this.activatedItem.active = false;
      item.active = false;
    },
    openPopup(item, index) {
      this.activatedItem = {};
      this.activatedItemIndex = -1;
      this.selectedItem = item;
      this.selectedItemIndex = index;

      this.dialogFormVisible = true;
    },
    onDrag(x, y, item) {
      if (this.templateInteractiveQuestions) {
        return false;
      } else {
        let xDifference = x - item.x;
        let yDifference = y - item.y;

        // Loop through each field in fieldsData
        this.fieldsData.forEach((field) => {
          if (item.inputType === "DIVISION") {
            // Check the condition and update the position only for matching fields
            if (
              field.is_in_division !== "" &&
              item.key === field.is_in_division
            ) {
              field.x += xDifference;
              field.y += yDifference;
            }
          } else if (
            x > field.x &&
            x + item.width < field.x + field.width &&
            y > field.y &&
            y + item.height < field.y + field.height &&
            field.inputType === "DIVISION"
          ) {
            item.is_in_division = field.key;
          } else {
            item.x = x;
            item.y = y;
          }
        });
        this.selectedItems.forEach((selectedKey) => {
          if (selectedKey !== item.key) {
            const selectedItem = this.fieldsData.find(
              (field) => field.key === selectedKey
            );
            if (selectedItem) {
              selectedItem.x += xDifference;
              selectedItem.y += yDifference;
            }
          }
        });
        item.x = x;
        item.y = y;
        this.selectedFields = [];
      }
    },
    checkFieldsPosdown(field, index) {
      let obj1 = { ...field };
      let addFieldToHistory = {
        data: obj1,
        action: "positionChange",
        index: index,
      };
      this.history.push(addFieldToHistory);
    },
    changePositionOfElementToDown() {
      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.dialogFormVisible == false
      ) {
        if (this.fieldsData[this.activatedItemIndex].y < 1935) {
          this.fieldsData[this.activatedItemIndex].y =
            this.fieldsData[this.activatedItemIndex].y + 10;
        }
      }
    },
    changePositionOfElementToUpward() {
      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.dialogFormVisible == false
      ) {
        if (this.fieldsData[this.activatedItemIndex].y > 10) {
          this.fieldsData[this.activatedItemIndex].y =
            this.fieldsData[this.activatedItemIndex].y - 10;
        }
      }
    },
    changePositionOfElementToRight() {
      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.dialogFormVisible == false
      ) {
        if (this.fieldsData[this.activatedItemIndex].x < 695) {
          this.fieldsData[this.activatedItemIndex].x =
            this.fieldsData[this.activatedItemIndex].x + 10;
        }
      }
    },
    changePositionOfElementToLeft() {
      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.dialogFormVisible == false
      ) {
        if (this.fieldsData[this.activatedItemIndex].x > 5) {
          this.fieldsData[this.activatedItemIndex].x =
            this.fieldsData[this.activatedItemIndex].x - 10;
        }
      }
    },
    existedOnDuplicateRules(key) {
      if (this.templateData?.all_duplicate_rules) {
        let found = false;
        this.templateData.all_duplicate_rules.forEach((rules) => {
          rules.forEach((e) => {
            if (e.selected_field == key) {
              found = true;
            }
          });
        });
        return found;
      }
      return false;
    },
    async deleteActiveElement() {
      const canDelete = await this.checkConditionsAndActions();
      if (!canDelete) {
        this.$message({
          message: "Can't delete, This field is used in Form rules.",
          type: "warning",
        });
        return;
      }
      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.dialogFormVisible == false
      ) {
        let existedOnDuplicateRules = this.existedOnDuplicateRules(
          this.activatedItem.key
        );
        if (existedOnDuplicateRules) {
          this.$message.warning(
            "Can't delete, This field is used in duplicate rules."
          );
          return;
        }
        // let formulas = this.lodash.filter(this.fieldsData, function (element) {
        //   return element.input_type == "FORMULA";
        // });
        let formulas = this.fieldsData.filter(
          (element) => element.input_type === "FORMULA"
        );
        if (formulas && formulas.length) {
          let selectedFields = [];
          await formulas.forEach((formulaField) => {
            formulaField.selected_fields
              ? selectedFields.push(...formulaField.selected_fields)
              : "";
          });
          let isExits = selectedFields.findIndex(
            (item) => item.key === this.activatedItem.key
          );
          // let isExits = this.lodash.findIndex(selectedFields, {
          //   key: this.activatedItem.key,
          // });
          if (isExits > -1) {
            this.$message.warning("This field is used in other areas.");
          } else {
            this.fieldsData.splice(this.activatedItemIndex, 1);
          }
        } else {
          this.fieldsData.splice(this.activatedItemIndex, 1);
        }
        let addFieldToHistory = {
          data: this.activatedItem,
          action: "delete",
          index: this.activatedItemIndex,
        };
        this.history.push(addFieldToHistory);
      }
    },
    onResize(x, y, width, height, item) {
      let percentageWidthChange = this.calculatePercentageWidthChange(
        item.width,
        width
      );
      let percentageHeightChange = this.calculatePercentageHeightChange(
        item.height,
        height
      );
      item.width = width;
      item.height = height;
      item.x = x;
      item.y = y;
      this.fieldsData.forEach((field) => {
        if (item.inputType === "DIVISION") {
          if (
            (field.is_in_division == "" || field.is_in_division == undefined) &&
            field.x > item.x &&
            field.x + field.width < item.x + item.width &&
            field.y > item.y &&
            field.y + field.height < item.y + item.height
          ) {
            field.is_in_division = item.key;
          }
          if (
            field.is_in_division !== "" &&
            item.key === field.is_in_division &&
            (field.x + field.width > item.x + item.width ||
              field.y + field.height > item.y + item.height)
          ) {
            field.width = field.width * (1 + percentageWidthChange / 100);
            field.x = field.x * (1 + percentageWidthChange / 100);
            field.y = field.y * (1 + percentageHeightChange / 100);
          }
        } else {
          item.width = width;
          item.height = height;
          item.x = x;
          item.y = y;
        }
      });
    },
    calculatePercentageWidthChange(originalWidth, newWidth) {
      const percentageWidthChange =
        ((newWidth - originalWidth) / originalWidth) * 100;
      return percentageWidthChange;
    },
    calculatePercentageHeightChange(originalHeight, newHeight) {
      const percentageHeihtChange =
        ((newHeight - originalHeight) / originalHeight) * 100;
      return percentageHeihtChange;
    },
    openFieldSettings(index) {
      this.isSelectView = true;
      this.dialogFormVisible = false;
      this.editableFieldIndex = index;
      this.editableField = this.fieldsData[index];
      this.showFieldSettings = true;
      if (this.editableField.inputType == "HORIZONTAL_LINE") {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
    },
    async updateEntityFields(data) {
      let data1 = this.checkBoxGroupArray.find((grp) => grp.key == data);
      bus.$emit("groupFieldUpdateOnChange", data1);
    },

    closeFieldSettings() {
      this.showFieldSettings = false;
      this.editableFieldIndex = -1;
    },

    handleCloseDrawer() {
      if (
        this.selectedDataTableIndex > -1 &&
        this.fieldsData[this.selectedDataTableIndex] &&
        this.editableFieldIndex > -1
      ) {
        this.fieldsData[this.selectedDataTableIndex].data_table_columns[
          this.editableFieldIndex
        ] = { ...this.editableField };
        // we need to rerender table
        this.reRenderDataTable = true;
        setTimeout(() => {
          this.reRenderDataTable = false;
        });
        this.selectedDataTableFields = [];
        this.showFieldSettings = false;
        this.editableFieldIndex = -1;
        this.selectedDataTableIndex = -1;
      } else {
        if (
          this.editableFieldIndex > -1 &&
          this.fieldsData[this.editableFieldIndex] &&
          this.fieldsData[this.editableFieldIndex].properties
        ) {
          let minValue = parseFloat(
            this.fieldsData[this.editableFieldIndex].properties.minlength_value
          );
          let maxValue = parseFloat(
            this.fieldsData[this.editableFieldIndex].properties.maxlength_value
          );

          if (maxValue <= minValue) {
            this.showFieldSettings = true;
            this.$alert(
              "Maximum value should be greater than Minimum value",
              "Warning",
              {
                confirmButtonText: "OK",
              }
            );
          } else {
            this.showFieldSettings = false;
            this.editableFieldIndex = -1;
          }
        } else {
          this.showFieldSettings = false;
          this.editableFieldIndex = -1;
        }
      }
    },
    closeFieldSettingsDrawar() {
      this.showFieldSettings = false;
      this.editableField = {};
      this.editableFieldIndex = -1;
    },

    openTemplateStylings() {
      if (!this.templateStyles) {
        this.templateStyles = {
          color: "",
          background: "",
          border_size: 0,
          border_color: "",
          border_type: "",
          input_background: "",
          label_color: "",
          labelStyle: "",
        };
      }

      this.templateStyleSettingsDialogVisible = true;
    },

    closeTemplateStyleSettings(data) {
      this.templateStyles = data.styles;
      if (data.isSubmit) {
        this.templateStyleSettingsDialogVisible = false;
      }
    },
    getFieldsFromElements(elements, skipSorting = false) {
      let duplicateKeyCounter = {};

      if (!skipSorting) {
        elements = elements.sort((a, b) => {
          if (a.y - b.y == 0) {
            return a.x - b.x;
          }
          return a.y - b.y;
        });
      }

      return elements.map((element) => {
        if (
          element.type == "FIELD" ||
          element.input_type == "HTML_CONTENT" ||
          element.inputtype == "HTML_CONTENT" ||
          element.input_type == "QUESTION" ||
          element.input_type == "IMAGE" ||
          element.input_type == "GIF" ||
          element.input_type == "VIDEO" ||
          element.input_type == "ACTION_BUTTON" ||
          element.input_type == "DATA_TABLE" ||
          element.input_type == "HEADING" ||
          element.input_type == "SINGLE_LINE_CONTENT" ||
          element.input_type == "ENTITY_TABLE" ||
          element.input_type == "ICON" ||
          element.input_type == "CALENDER" ||
          element.input_type == "DIVISION" ||
          element.input_type == "PARAGRAPH" ||
          element.input_type == "HORIZONTAL_LINE" ||
          element.input_type == "CAROUSEL"
        ) {
          let newKey = this.generateKeyForField(
            element.key,
            element.question_id ||
              element.label ||
              element.question_text ||
              element.input_type
          );
          if (duplicateKeyCounter[newKey]) {
            element.key = newKey + "_" + duplicateKeyCounter[newKey];
            duplicateKeyCounter[newKey]++;
          } else {
            duplicateKeyCounter[newKey] = 1;
            element.key = newKey;
          }
        }
        return element;
      });
    },
    prepareFieldKeysForDataTableFields(allFields) {
      for (let i = 0; i < allFields.length; i++) {
        if (
          allFields[i].input_type == "DATA_TABLE" &&
          allFields[i].data_table_columns &&
          allFields[i].data_table_columns.length
        ) {
          allFields[i].data_table_columns = this.getFieldsFromElements(
            allFields[i].data_table_columns,
            true
          );
        }
      }
      return allFields;
    },
    prepareFinalSavingData() {
      try {
        let modifiedData = [...this.fieldsData];
        // let modifiedData = JSON.parse(JSON.stringify(this.fieldsData));
        modifiedData = modifiedData.map((el, index) => {
          el.order = index;
          el.visibility = true;
          if (el.input_type == "QUESTION") {
            if (el.question_type == "MCQ") {
              el.validations.data_type = el.validations.type =
                el.validations.options_data_type || "STRING";
            } else if (el.question_type == "TEXT") {
              el.validations.data_type = el.validations.type = "STRING";
            } else {
              el.validations.data_type = el.validations.type = "BOOLEAN";
            }
          } else {
            el.validations.type = this.getTypeFromInputType(el.input_type);
            el.validations.data_type =
              el.validations.options_data_type ||
              this.getTypeFromInputType(el.input_type);
          }
          el.validations.required =
            el.validations.required == 1 || el.validations.required === true;
          if (!el.options || !el.options.length) {
            delete el.options;
          }
          if (el.input_type == "QUESTION") {
            el.label = el.question_text ? el.question_text.trim() : undefined;
          }
          if (
            el.input_type == "SIGNATURE" &&
            el.filled_by == "SENDER" &&
            el.source
          ) {
            el.default_value = el.source;
          }
          el.description = el.description ? el.description.trim() : undefined;
          el.placeholder = el.placeholder ? el.placeholder.trim() : undefined;

          if (el.label == "") delete el.label;
          if (el.key == "") delete el.key;
          if (el?.properties?.number_type == "") {
            let pros = { ...el.properties };
            delete pros.number_type;
            el.properties = { ...pros };
          }

          return el;
        });

        modifiedData = this.getFieldsFromElements(modifiedData);

        modifiedData = this.prepareFieldKeysForDataTableFields(modifiedData);

        // this.prepareDefaultTitleForAdmin();

        if (!modifiedData.length) {
          return this.$message.warn("Please Add Some Fields");
        }
        const data = {
          name: this.name.trim(),
          icon: this.templateIconPath,
          user_primary_template: this.userPrimaryTemplate || false,
          description: this.description ? this.description.trim() : "",
          free_style: this.free_style,
          use_interactive_questionare:
            this.templateInteractiveQuestions || false,
          interactive_questionare_type:
            this.templateInteractiveQuestionsType || "",
          templateTryUsingAI: this.templateTryUsingAI || false,
          slug: this.getTemplateSlug,
          print_default_document_template: this.printingDocument,
          show_success_notification_after_save: this.showSuccessNotification,
          predefined_template_id: this.$route.query.default_template_id,
          page_base_width: this.currentFormBuilderWidth,
          type: this.type || "CONFIGURABLE",
          styles: this.templateStyles,
          is_public: this.templateSharingMode,
          is_repeatable: this.templateReatingStatus,
          templateGroups: this.templateGroups,
          sections:
            modifiedData && modifiedData.length
              ? [
                  {
                    // default one section
                    type: "DEFAULT", // Todo need to change
                    fields: modifiedData,
                    checkBoxGroup: this.checkBoxGroupArray,
                  },
                ]
              : [],
          primary_fields: [],
          rules: [...this.templateRules],
          all_duplicate_rules:
            this.templateData && this.templateData.all_duplicate_rules
              ? this.templateData.all_duplicate_rules
              : [],
          duplicate_message:
            this.templateData && this.templateData.duplicate_message
              ? this.templateData.duplicate_message
              : [],
          duplicate_query_type:
            this.templateData && this.templateData.duplicate_query_type
              ? this.templateData.duplicate_query_type
              : "$and",
        };

        if (this.userPrimaryTemplate) {
          data.user_types = this.userTypes;
        }

        return data;
      } catch (error) {
        this.$message.error(
          "An error occurred while preparing data. Please try again."
        );
      }
    },
    generateKeyForField(previousKey, label) {
      if (previousKey) {
        return previousKey;
      }
      return makeKey(label);
    },

    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        MULTI_LINE_TEXT: "STRING",
        RANDOM_TEXT: "RANDOM_TEXT",
        SINGLE_LINE_CONTENT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE",
        TIME: "TIME",
        NUMBER: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "BOOLEAN",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "STRING",
        CHECKBOX: "BOOLEAN",
        RADIO: "BOOLEAN",
        HEADING: "STRING",
        SIGNATURE: "STRING",
        HTML_CONTENT: "STRING",
        GLOBAL_VARIABLE: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "IMAGE",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "NUMBER",
        FAX: "NUMBER",
        ZIP_CODE: "NUMBER",
        CURRENCY: "NUMBER",
        PHONE_COUNTRY_CODE: "NUMBER",
        QUESTION: "STRING",
        DATE_TIME_RANGE: "DATE_TIME",
        WEEKDAYS: "WEEKDAYS",
        TIME_RANGE: "TIME_RANGE",
        HYPER_LINK: "HYPER_LINK",
        AUDIO: "AUDIO",
        QR: "QR",
      };

      return obj[inputType];
    },
    splitOptionsIntoArray(options) {
      return options.split(",").map((x) => x.trim());
    },
    async backToTemplates() {
      if (
        this.getSingleCompanyTemplate &&
        this.getSingleCompanyTemplate.name &&
        this.name &&
        this.name != this.getSingleCompanyTemplate.name
      ) {
        this.dialogVisibleForSavingData = true;
      } else if (
        this.getSingleCompanyTemplate &&
        this.getSingleCompanyTemplate.sections &&
        this.getSingleCompanyTemplate.sections[0] &&
        this.getSingleCompanyTemplate.sections[0].fields &&
        this.getSingleCompanyTemplate.sections[0].fields.length &&
        this.fieldsData &&
        this.fieldsData.length &&
        this.fieldsData.length !=
          this.getSingleCompanyTemplate.sections[0].fields.length
      ) {
        this.dialogVisibleForSavingData = true;
      } else if (
        this.getSingleCompanyTemplate &&
        this.templateReatingStatus !=
          this.getSingleCompanyTemplate.is_repeatable
      ) {
        this.dialogVisibleForSavingData = true;
      } else if (
        this.getSingleCompanyTemplate &&
        this.getSingleCompanyTemplate.description &&
        this.description != this.getSingleCompanyTemplate.description
      ) {
        this.dialogVisibleForSavingData = true;
      } else {
        let comparision_between_previous_state_and_currentstate_of_fields = false;
        this.getSingleCompanyTemplate.sections[0].fields.forEach(
          (prevVal, prevIndex) => {
            this.fieldsData.forEach((currentVal, currentIndex) => {
              let fieldsChanged = false;
              if (
                currentIndex === prevIndex &&
                prevVal &&
                prevVal.key &&
                currentVal &&
                currentVal.key &&
                currentVal.key === prevVal.key &&
                prevVal.filled_by &&
                currentVal.filled_by &&
                currentVal.filled_by !== prevVal.filled_by
              ) {
                fieldsChanged = true;
              }
              if (
                currentIndex === prevIndex &&
                (currentVal.placeholder !== prevVal.placeholder ||
                  currentVal.x !== prevVal.x ||
                  currentVal.y !== prevVal.y ||
                  currentVal.input_type !== prevVal.input_type ||
                  currentVal.inputType !== prevVal.inputType ||
                  currentVal.width !== prevVal.width ||
                  currentVal.height !== prevVal.height)
              ) {
                fieldsChanged = true;
              }
              if (fieldsChanged) {
                comparision_between_previous_state_and_currentstate_of_fields = true;
              }
            });
          }
        );
        if (comparision_between_previous_state_and_currentstate_of_fields) {
          this.dialogVisibleForSavingData = true;
        } else {
          if (this.dialogTemplatePrimaryData) {
            this.dialogTemplatePrimaryData = false;
          } else {
            this.dialogTemplatePrimaryData = false;
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({ path: "/template", query });
          }
        }
      }
    },
    removeExistedField(index) {
      this.fieldsData.splice(index, 1);
    },
    closeCreateNewTemplatePopup() {
      this.dialogTemplatePrimaryData = false;
    },
    moveUp(index) {
      if (index > 0) {
        let data = this.swap(this.fieldsData, index, index - 1);
        this.fieldsData = [];
        this.fieldsData = data;
      }
    },
    moveDown(index) {
      if (index < this.fieldsData.length - 1) {
        let data = this.swap(this.fieldsData, index, index + 1);
        this.fieldsData = [];
        this.fieldsData = data;
      }
    },
    swap(list = [], from = -1, to = -1) {
      if (list.length) {
        let temp = list[to];
        list[to] = list[from];
        list[from] = temp;
        return list;
      }
      return list;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        // let isDefalutPos = this.templateStyles.labelStyle;
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.templateStyles.label_color
          ? `color: ${this.templateStyles.label_color}`
          : "";
      }
      return borderStyle;
    },
    submitDisable() {
      return !this.fieldsData.length;
    },

    getIconPath(icon) {
      this.templateIconPath = icon;
      this.iconPickerdialogVisible = false;
    },
    async setTemplateInfo(templateId, defaultTemplate = true) {
      this.loading = true;
      this.loadingText = "Fetching Template...";
      if (defaultTemplate) {
        this.defaultTemplatedId = templateId;

        try {
          if (defaultTemplate) {
            await this.$store.dispatch(
              "companyTemplates/fetchSingleDefaultTemplate",
              templateId
            );
            this.templateData = { ...this.getSingleDefaultTemplate };
            await this.setTemplateElements(
              this.getSingleDefaultTemplate,
              templateId
            );
          }
        } catch (err) {
          console.log("setTemplateInfo", err);
        }
      } else {
        try {
          // await this.$store.dispatch(
          //   "companyTemplates/fetchSingleCompanyTemplate",
          //   templateId
          // );
          let getSingleCompanyTemplate = await fetchSingleCompanyTemplate(
            templateId
          );
          this.templateData = { ...getSingleCompanyTemplate };
          updateTemplateData(templateId, this.templateData);
          await this.setTemplateElements(getSingleCompanyTemplate, templateId);
        } catch (err) {
          console.log(err);
        }
      }

      this.dialogTemplatePrimaryData = this.name ? false : true;

      setTimeout(() => {
        this.loading = false;
        this.loadingText = "";
      }, 1500);
    },
    async setTemplateElements(templateData, templateId) {
      this.name = templateData.name.trim();
      this.description = templateData.description
        ? templateData.description.trim()
        : undefined;
      this.free_style = templateData.free_style;
      this.templateIconPath = templateData.icon;
      this.userPrimaryTemplate = templateData.user_primary_template || false;
      this.userTypes = templateData.user_types || [];
      this.templateSharingMode = templateData.is_public;
      this.templateReatingStatus = templateData.is_repeatable;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.storedFormBuilderWidth = templateData.page_base_width;
      this.titles = templateData.titles;
      this.templateStyles = templateData.styles;
      this.templateGroups = templateData.templateGroups;
      this.templateInteractiveQuestions =
        templateData.use_interactive_questionare;
      this.templateInteractiveQuestionsType =
        templateData.interactive_questionare_type;
      this.templateTryUsingAI = templateData.templateTryUsingAI;
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
      this.printingDocument = templateData.print_default_document_template;
      this.showSuccessNotification =
        templateData.show_success_notification_after_save;

      if (
        templateData.primary_fields &&
        templateData.primary_fields[0] &&
        templateData.primary_fields[0].key
      ) {
        this.primaryField = templateData.primary_fields[0].key;
      }
      if (this.type == "STANDARD") {
        this.showFields = false;
      } else {
        let defaultSection = templateData.sections.find(
          (x) => x.type == "DEFAULT"
        ); // Todo need to change for multiple sections
        // this.fieldsData = defaultSection.fields;
        await this.fetchOtherTemplates(defaultSection.fields, true);
        this.checkBoxGroupArray = defaultSection.checkBoxGroup;
        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields,
          templateId
        );
        this.fieldsData = this.fieldsData.sort((a, b) => a.y - b.y);
        // this.fieldsData.forEach(async (element) => {
        //   if (element.input_type == "ACTION_BUTTON") {
        //     await this.getFieldsForActionButton(
        //       element.action_button_repeating_template
        //     );
        //   }
        // });      if (this.history.length > 0) {
        if (
          this.fieldsData[this.fieldsData.length - 1].y +
            this.fieldsData[this.fieldsData.length - 1].height <=
          5000
        ) {
          this.formHeight = 5000;
        } else {
          this.formHeight = this.fieldsData[this.fieldsData.length - 1].y + 500;
        }
      }
      this.fieldsData = this.mapDataTableKeys(this.fieldsData);
      this.fieldsData = this.fieldsData.map((e) => {
        if (e.is_global_variable) {
          e = {
            ...e,
            ...this.allGlobalVariablesObject[e.global_variable_id],
          };
        }
        return e;
      });
    },
    mapDataTableKeys(fields) {
      return fields.map((el) => {
        if (el.inputType == "DATA_TABLE" || el.inputType == "ENTITY_TABLE") {
          el.data_table_columns = el.data_table_columns
            .filter((e) => e)
            .map((e) => {
              e.data_table_key = el.key;
              return e;
            });
        }
        return el;
      });
    },
    closeTemplateSettings({ templateSettings, submit = false }) {
      console.log(templateSettings, submit);
    },
    openDocumentUserSettings(submit = false) {
      this.showTemplateSettings = true;
      console.log(submit);
    },
    prepareDefaultTitleForAdmin() {
      let adminTitle = this.titles.find(
        (titleData) =>
          titleData.user_types.indexOf(this.getAdminUserType._id) > -1
      );

      if (!adminTitle) {
        this.titles.push({
          title: this.name,
          user_types: [this.getAdminUserType._id],
        });
      }
    },
    async getFieldsForActionButton(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (
          this.getSingleCompanyTemplate &&
          this.getSingleCompanyTemplate.sections &&
          this.getSingleCompanyTemplate.sections.length
        ) {
          this.actionButtonTemplateFields[templateId] =
            this.getSingleCompanyTemplate.sections[0].fields;
        }
      } catch (error) {
        console.log("getFieldsForActionButton", error);
      }
    },
    getActionButtonFields(templateId) {
      let fieldData = "";
      if (
        this.actionButtonTemplateFields[templateId] &&
        this.actionButtonTemplateFields[templateId].length
      ) {
        fieldData = this.actionButtonTemplateFields[templateId]
          .map((e) => e.label)
          .join(", ");
      }
      return fieldData;
    },
    openAddGroupDialog() {
      this.showAddData = true;
    },
    async submitNewGroup() {
      this.checkBoxCurrentObj = {};
      this.check_box_group_form.filled_by = this.editableField.filled_by;
      this.dialogErr = false;
      let hasVal = -1;
      hasVal = this.checkBoxGroupArray.findIndex(
        (g) => g.label == this.check_box_group_form["label"]
      );
      if (hasVal > -1) {
        this.dialogErr = true;
      } else if (
        this.check_box_group_form.min_value >
        this.check_box_group_form.max_value
      ) {
        this.$message.error("Minimum value cannot exceed maximum value.!");
      } else if (!this.check_box_group_form.filled_by) {
        this.$message.error("Kindly choose fields filled by.!");
      } else {
        this.check_box_group_form["key"] = await this.generateKeyForField(
          "",
          this.check_box_group_form["label"]
        );
        this.editableField.group_key = this.check_box_group_form.key;

        let groupObjects = await JSON.parse(
          JSON.stringify(this.check_box_group_form)
        );
        this.checkBoxCurrentObj = groupObjects;
        this.checkBoxGroupArray.push(groupObjects);
        this.showAddData = false;
        this.cancelGroupPopup();
      }
    },
    cancelGroupPopup() {
      this.check_box_group_form.label = "";
      this.check_box_group_form.key = "";
      this.check_box_group_form.max_value = 1;
      this.check_box_group_form.min_value = 1;
      this.check_box_group_form.show_checkbox_label = false;
      this.check_box_group_form.filled_by = "";
    },
    // Drag & drop
    mouseDown() {
      this.isDragging = true;
    },
    mouseUp() {
      this.isDragging = false;
    },
    onDocumentDrag(evt) {
      if (evt.clientX == 0 && evt.clientY == 0) {
        return;
      }
      this.mouseX = evt.clientX;
      this.mouseY = evt.clientY;
    },
    onDocumentDragMobile(evt) {
      let touch =
        (evt.touches && evt.touches[0]) ||
        (evt.pointerType && evt.pointerType === "touch" && evt);
      let clientX = (touch || evt).clientX;
      let clientY = (touch || evt).clientY;
      if (clientX == 0 && clientY == 0) {
        return;
      }
      this.mouseX = clientX;
      this.mouseY = clientY;
    },
    onDraggableElementMouseDown(e) {
      var parent = e.target.parentNode;
      var rect = parent.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.
      this.deltaX = x;
      this.deltaY = y;
    },
    onDraggableElementMouseDownMobile(evt) {
      let touch =
        (evt.touches && evt.touches[0]) ||
        (evt.pointerType && evt.pointerType === "touch" && evt);
      let clientX = (touch || evt).clientX;
      let clientY = (touch || evt).clientY;

      var parent = evt.target.parentNode;
      var rect = parent.getBoundingClientRect();
      var x = clientX - rect.left; //x position within the element.
      var y = clientY - rect.top; //y position within the element.
      this.deltaX = x;
      this.deltaY = y;
    },
    getAllFieldList() {
      try {
        if (fieldsList) {
          this.fieldActions = [
            ...fieldsList.allFormFields,
            ...fieldsList.allContentFields,
            ...fieldsList.allAdvancedFields,
            ...fieldsList.utilityComponents,
          ];
          this.fieldActions2 = [...fieldsList.allContentFields];
          this.fieldActions3 = [...fieldsList.allFormFields];
          this.advancedFieldActions = [...fieldsList.allAdvancedFields];
          this.utilityComponents = [...fieldsList.utilityComponents];
        }
      } catch (error) {
        console.log("getAllFieldList", error);
      }
    },

    async openTableColumnSettings(tableIndex, colData) {
      this.selectedDataTableIndex = tableIndex;
      if (this.fieldsData[tableIndex]?.data_table_columns) {
        this.selectedDataTableFields =
          this.fieldsData[tableIndex].data_table_columns;
      }
      this.editExistedTableField(colData.column, colData.index);
    },

    openTableCoulmnFieldConfigSettings(tableIndex, colData) {
      this.selectedDataTableIndex = tableIndex;
      if (this.fieldsData[tableIndex]?.data_table_columns) {
        this.selectedDataTableFields =
          this.fieldsData[tableIndex].data_table_columns;
      }
      this.openDataTableFieldSettings(colData.column, colData.index);
    },

    openDataTableFieldSettings(field, index) {
      this.editableFieldIndex = index;
      this.isSelectView = true;
      this.dialogFormVisible = false;
      this.editableField = field;
      this.showFieldSettings = true;
    },
    handleEnterKey() {
      this.checkPrimaryTemplates();
    },
    validateRequiredFields(requiredFields) {
      if (!requiredFields) return true;
      return Object.keys(requiredFields).every((key) => requiredFields[key]);
    },
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this);
    this.$store.commit("entities/setAllEntitiesInTemplate", {}, { root: true });
    this.$store.commit(
      "companyTemplates/setTemplateDataTempVariable",
      {},
      { root: true }
    );
    this.$store.commit(
      "companyTemplates/setAllCompanyTemplatesData",
      { data: [] },
      { root: true }
    );
    this.$store.commit("entities/setAllEntities", 0);
    this.$store.commit("globalVariables/setAllGlobalVariables", [], {
      root: true,
    });
  },
  watch: {
    duplicateRulesModal(newValue) {
      if (!newValue) {
        this.dialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-field {
  background-color: rgba(0, 0, 255, 0.1);
}
#svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.line {
  stroke-width: 1px;
  stroke: rgb(9, 60, 230);
  z-index: 200 !important;
}

:root {
  --base-grid: 8px;
  --colour-body-background: #efefef;
  --colour-background: #fff;
  --colour-background-folded: #f5f5f5;
  --colour-background-stripes: rgba(255, 255, 255, 0.5);
  --colour-text: #1a1a1a;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}

::v-deep .setting-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 100;
}

.main-content {
  background-color: #ffffff;
}

.form-fields-set {
  top: -0px;
  position: relative;
  background: #ffffff;
  max-height: 600px !important;
  overflow-y: scroll !important;
  // overflow-y: scroll !important;
}

.temp-save {
}

.submit-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: #285ed3 !important;
  color: #ffffff !important;

  &:hover {
    color: #285ed3 !important;
    background: #ffffff !important;
    border: 1px solid #285ed3;
  }
}

.sub-btn {
  height: 38px !important;
  border-radius: 2px;
  border: 1px solid #285ed3;
  background: #285ed3 !important;
  color: #ffffff !important;

  &:hover {
    color: #285ed3 !important;
    background: #ffffff !important;
    border: 1px solid #285ed3;
  }
}

.fields-title {
  font-size: 13px;
  letter-spacing: 0.5px;
  margin-bottom: 3px;
  padding-left: 3px;
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  color: #3f3356;
}

.edit-new-template-view {
  /* padding-top: 1em; */
  /* background-color: #f8f9fa; */
  .templates-title-block {
    background-color: #ffffff;
    z-index: 250;
    margin-bottom: 10px;
    padding: 10px 20px;
    // top: 65px;

    .form-group {
      display: flex;
      grid-template-columns: 180px auto;
      gap: 20px;
      margin-bottom: 10px;

      span {
        text-align: right;
        color: #3f3356;
        font-weight: 500;
      }
    }

    .title-section {
      display: grid;
      grid-template-columns: 5em auto;
    }
  }

  .inner-navbar {
    border-bottom: 1px solid #c2d9ef;
    padding-bottom: 25px;
    margin-bottom: 25px;

    h4 {
      font-weight: 500;
      color: #1b487e;
      font-size: 1em;
    }
  }

  .common-field-preview {
    padding: 10px 15px;
    border: 1px solid #c9c9c9;
    border-radius: 8px;
    min-height: 20px;
  }

  .multi-line-text-field-preview {
    padding: 10px 15px 60px 15px;
  }

  .flip-list-move {
    transition: transform 1s;
  }

  .input-placeholder {
    color: grey;
    opacity: 0.8;
  }

  #field-group {
    display: flex;
    flex-wrap: wrap;

    #each-field {
      width: 33.33%;
    }
  }

  .up-down-arrow {
    button {
      padding: 2px;
    }
  }

  .el-button.is-circle {
    padding: 8px;
  }

  .templates-title-block {
    position: fixed;
  }

  // Fields
  .scrollable-container {
    .field-element {
      color: #33475b;
      background-color: #f5f8fa !important;
      font-size: 12px;
      font-weight: normal;
      position: relative;
      padding: 0.3em 0.1em;
      width: 100%;
      margin-bottom: 8px;
      display: block;
      //font-size: 0.93em;
      //font-weight: 500;
      white-space: 1px;
      line-height: 1.2;
      min-height: 35px;
      text-align: left;
      padding-left: 33px;
      border: 1.5px solid #cbd6e2;
      border-radius: 2px;
      transition: 0.25s all ease-in-out;
      -webkit-transition: 0.25s all ease-in-out;

      & span img {
        position: absolute;
        top: 2.5px;
        left: -2px;
        width: 20px;
        transition: 0.25s all ease-in-out;
        -webkit-transition: 0.25s all ease-in-out;
      }

      & span > .icon-hover {
        opacity: 0;
      }

      &:focus,
      &:hover {
        color: #ffffff;
        background-color: #405bd0 !important;

        & span > .icon-hover {
          opacity: 1;
        }

        & span > .icon {
          opacity: 0;
        }
      }

      & + .el-button {
        margin-left: 0 !important;
      }

      & > svg {
        fill: #ffffff;
      }

      img {
        max-height: 25px;
        margin: 4px 10px;
        vertical-align: middle;
      }

      span {
        margin-left: 0px;
        vertical-align: middle;
        line-height: 1;

        i {
          width: 100%;
          font-size: 1em;
          padding-bottom: 0.5em;
        }
      }
    }
  }

  // Fields
  .base-parent {
    margin-bottom: 5%;
    position: relative;
    overflow: hidden;
    margin-top: 60px;
    // top: -9px;
    padding-left: 10px;

    .form-builder {
      position: relative;
      // width: 100%;
      width: 1280px;

      // overflow-y: scroll;
      // max-height: calc(100vh - 142px);
      @media (min-width: 992px) {
        max-height: calc(100vh - 163px);
      }

      background-color: transparent;
      border: none;

      // box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
      .form-parent {
        position: relative;
        min-height: 5000px;
        width: 100%;
        background-image: linear-gradient(
            0deg,
            transparent 24%,
            rgba(0, 0, 0, 0.05) 25%,
            rgba(0, 0, 0, 0.05) 26%,
            transparent 27%,
            transparent 74%,
            rgba(0, 0, 0, 0.05) 75%,
            rgba(0, 0, 0, 0.05) 76%,
            transparent 77%,
            transparent
          ),
          linear-gradient(
            90deg,
            transparent 24%,
            rgba(0, 0, 0, 0.05) 25%,
            rgba(0, 0, 0, 0.05) 26%,
            transparent 27%,
            transparent 74%,
            rgba(0, 0, 0, 0.05) 75%,
            rgba(0, 0, 0, 0.05) 76%,
            transparent 77%,
            transparent
          );
        background-size: 40px 40px;

        .form-fields-holder {
          position: absolute;
          left: 0;
          top: 0;
          width: 98%;
          height: 100%;

          .each-element img {
            max-width: 100%;
          }

          ::v-deep {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 500;
              text-transform: capitalize;
            }
          }

          .el-input__inner,
          .el-textarea__inner {
            height: 100% !important;
            min-height: 30px !important;
            line-height: inherit !important;
          }

          img {
            height: inherit;
            width: inherit;
          }

          .el-button {
            height: inherit;
            width: inherit;
          }
        }
      }

      .form-parent-no-grid {
        position: relative;
        min-height: 5000px;
        width: 100%;
        background-size: 40px 40px;

        .form-fields-holder {
          position: absolute;
          left: 0;
          top: 0;
          width: 98%;
          height: 100%;

          // height: 100%;
          .each-element img {
            max-width: 100%;
          }

          ::v-deep {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 500;
              text-transform: capitalize;
            }
          }

          .el-input__inner,
          .el-textarea__inner {
            height: 100% !important;
            min-height: 30px !important;
            line-height: inherit !important;
          }

          img {
            height: inherit;
            width: inherit;
          }

          .el-button {
            height: inherit;
            width: inherit;
          }
        }
      }
    }
  }

  // Header Form
  .btn-back {
    margin: 0 auto;
    display: block;
    padding: 10px !important;
  }
}
</style>
<style lang="scss">
.top-bar {
  border-bottom: 1.2px solid #cbd6e2;
  padding-bottom: 5px;

  @media screen and (min-width: 1000px) {
    width: 100vw;
  }

  @media screen and (min-width: 1200px) {
    width: 100vw;
  }
}

.save-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  background: #3bc941 !important;
  color: white !important;

  &:hover {
    background: transparent !important;
    border: 1px solid #3bc941 !important;
    color: #3bc941 !important;
  }
}

.preview-btn {
  border-radius: 2px !important;
  padding: 0.15em 0.35em;
  border: 1px solid #285ed3 !important;
  background: transparent !important;
  color: #285ed3 !important;

  &:hover {
    color: white !important;
    background: #285ed3 !important;
  }
}

.input-select {
  text-align: center;
}

.temp-des,
.input-lg {
  background-color: #eff3fa !important;
  border: 0.1px solid #cdddfc;
  box-shadow: 0 0.5px 1px 0 rgba(202, 202, 202, 0.2),
    0 0.5px 3px 0 rgba(206, 206, 206, 0.19);
}

.temp-title {
  color: #33475b;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.temp-sub-title {
  line-height: 18px;
  color: #33475b;
  font-size: 14px;
  font-weight: 300;
}

.vdr {
  border: 1px dotted #41ad20;
}

.dragdrop-item {
  border: none !important;

  &:hover {
    cursor: pointer !important;
    border: 1px solid slategray !important;
  }

  &.dragdrop-item-active {
    border: 1px dotted #41ad20 !important;
    max-width: 1180px;
  }

  &.form-style-active {
    border: none !important;
    max-width: 1180px;
    box-shadow: 0 0 0 2px #0075e3 !important;
  }

  &.dragging {
    .el-textarea__inner,
    .el-input__inner {
      cursor: move !important;
    }
  }

  // Text / Textarea  Fields
  .el-textarea__inner:focus,
  .el-input__inner:focus {
    outline: none;
    cursor: crosshair;
    border-color: #c0c4cc;
  }

  .el-textarea__inner:hover,
  .el-input__inner:hover {
    outline: none;
    cursor: pointer;
    border-color: #c0c4cc;
  }
}

.dragdrop-item-relative {
  border: none !important;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;

  &:hover {
    cursor: pointer !important;
  }

  &.dragdrop-item-active {
    border: 1px dashed #41ad20 !important;
  }

  &.dragging {
    .el-textarea__inner,
    .el-input__inner {
      cursor: move !important;
    }
  }

  // Text / Textarea  Fields
  .el-textarea__inner:focus,
  .el-input__inner:focus {
    outline: none;
    cursor: crosshair;
    border-color: #c0c4cc;
    width: 100%;
  }

  .el-textarea__inner:hover,
  .el-input__inner:hover {
    outline: none;
    cursor: pointer;
    border-color: #c0c4cc;
  }
}

// Dragdrop Stylings Custom

.dragdrop-holder {
  position: absolute;
  border-radius: 5px;
  background-color: #41ad20;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  box-model: border-box;
  -webkit-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.dragdrop-holder-tl {
  top: -10px;
  left: -10px;
  cursor: nw-resize;
}

.sidemenu {
  height: 450px;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.dragdrop-holder-tm {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  cursor: n-resize;
}

.dragdrop-holder-tr {
  top: -10px;
  right: -10px;
  cursor: ne-resize;
}

.dragdrop-holder-ml {
  top: 50%;
  margin-top: -5px;
  left: -10px;
  cursor: w-resize;
}

.dragdrop-holder-mr {
  top: 50%;
  margin-top: -5px;
  right: -10px;
  cursor: e-resize;
}

.dragdrop-holder-bl {
  bottom: -10px;
  left: -10px;
  cursor: sw-resize;
}

.dragdrop-holder-bm {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  cursor: s-resize;
}

.dragdrop-holder-br {
  bottom: -10px;
  right: -10px;
  cursor: se-resize;
}

.dragdrop-holder-ml:hover,
.dragdrop-holder-mr:hover,
.dragdrop-holder-bm:hover,
.dragdrop-holder-tm:hover,
.dragdrop-holder-tl:hover,
.dragdrop-holder-tr:hover,
.dragdrop-holder-bl:hover,
.dragdrop-holder-br:hover {
  transform: scale(1.2);
  border-color: #454545;
  z-index: 10;
}

.edit-template-modal {
  .el-form-item__label {
    line-height: 30px;
  }

  .el-form-item {
    margin-bottom: 1em !important;
  }
}

// Icon Picekers
.iconslistScrollable {
  .template-icon {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ffffff;
    height: 95px;

    img {
      height: 50px;
    }

    &:hover,
    &:focus {
      border: 1px dashed #a8aafc;
    }
  }
}

.icon-dropdown-selection {
  margin-right: 0.75em;

  i {
    font-size: 1.5em;
  }
}

.el-dialog__header {
  background-image: linear-gradient(92.14deg, #323b6a 50.18%, #f754a2 103.08%);
}

.el-dialog {
  background-color: #ffffff !important;
}

.title-management-dialog {
  .el-dialog__wrapper {
    .el-dialog__header {
      background-color: #ffffff !important;
      border-bottom: 1px solid #efefef !important;

      .el-dialog__title {
        color: #333333 !important;
        font-weight: 400 !important;
        font-size: 1.1em !important;
      }
    }
  }

  .add-template-title {
    padding: 10px;
  }
}

.el-drawer__container {
  .el-drawer__header {
    background-color: #f9f9f9;
    border: 1px solid #f3f3f3;
    padding-bottom: 1em;
  }
}

.template-sharing-button {
  margin-top: 5px;
  text-align: center;
  border: 0.5px solid #cbd6e2 !important;
  padding: 0.25em 0 0.75em;
  background-color: #f5f8fa !important;
  border-radius: 4px;
  width: 240px;

  .label {
    padding-bottom: 3px;
    font-size: 13px;
    // color: #677788;
    letter-spacing: 0.5px;
  }

  .el-switch__label {
    padding: 2px 8px;
  }

  .el-switch__label.is-active {
    color: #1b487e;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(140, 152, 164, 0.05);
  }
}

.scrollable-container {
  /* -webkit-column-count: 2;
  -webkit-column-gap: 8px; */
  .field-element {
    & > span {
      display: flex;
    }
  }
}

.template-styling-setting {
  background-color: #ffffff !important;
  padding: 5px !important;
  border-radius: 20px !important;
  border: 1px solid #ffffff;

  &:hover {
    border-top-color: #748bf3 !important;
    border-right-color: #7fec5d !important;
    border-left-color: #ff7070 !important;
    border-bottom-color: #25939b !important;
  }

  & > span {
    display: flex;
    align-items: center;
    column-gap: 7px;
    justify-content: space-between;
  }

  img {
    max-width: 28px;
  }

  i {
    font-size: 20px;
  }
}

// .center-block{
//   height: 15px;
//   line-height: 15px;
//   padding: 10px;
// }

.template-settings-drawer {
  .el-drawer__header {
    background-color: #fdfdfd;

    & > :first-child {
      outline: none;
      color: #1b487e;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 0.5em;
    }
  }

  .el-drawer__body {
    padding: 1.5em;
  }
}

.fieldSet__box {
  display: grid;

  .draggable {
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */
  }

  .draggable:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
}

.entityFieldSets {
  margin: 0 5px 0 10px;
  border: none;

  .fields-title {
    padding-left: 5px;
    font-weight: 500;
    font-size: 1.2em;
  }

  .el-scrollbar__wrap {
    max-height: 70vh;
    overflow-y: auto;
  }

  .el-collapse-item__wrap {
  }

  .el-collapse-item {
    .el-collapse-item__header {
      /* background-color: #f8f9fa; */
    }

    &.is-active {
      /* background-color: #f8f9fa; */
      .el-collapse-item__header {
        background-color: transparent;
      }
    }
  }
}
</style>
<style lang="scss">
.tempTitle:before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 99px;
  margin-top: 8px;
}

.change-document-dlg {
  .el-dialog__body {
    scrollbar-width: none !important;
  }
}

.form-fields-holder {
  .draggable {
    z-index: 100 !important;
  }

  #svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .line {
    stroke-width: 1px;
    stroke: rgb(9, 60, 230);
    z-index: 200 !important;
  }
}

#drag-template-background {
  .document-editor-container {
    .content-fields {
      textarea.el-textarea__inner {
        min-height: 10px !important;
        // line-height: 15px !important;
      }
    }
  }
}

textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.content-fields {
  min-width: 15px !important;
  min-height: 15px !important;

  .field-textarea {
    &.textarea-nowrap {
      textarea {
        white-space: nowrap !important;
      }
    }

    height: 100% !important;

    &.el-textarea {
      vertical-align: baseline !important;
    }
  }

  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }

  // position:relative; // for tooltip
  &[data-text*="DATE "] {
    background: none !important;
  }

  &:before {
    content: attr(data-text);
    position: absolute;
    top: -25px;
    -webkit-transform: translateY(-50%);
    left: -17px;
    margin-left: 15px;
    width: 142px;
    height: 10px;
    padding: 10px;
    border-radius: 9px;
    background: #555;
    color: white;
    text-align: center;
    line-height: 10px;
    visibility: hidden;
    opacity: 0;
    /* define initial transition property */
    transition: opacity 1s;
  }

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 47px;
    margin-left: -11px;
    border-width: 9px 9px 10px 0px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    visibility: hidden;
    opacity: 0;
    /* define initial transition property */
    transition: opacity 1s;
  }

  &:hover:before {
    visibility: visible;
    opacity: 1;
  }

  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  &:focus-visible {
    outline: none !important;
  }

  &.el-textarea {
    width: 100% !important;
    height: 100% !important;
  }

  padding: 0 !important;

  &.my-active-class {
    border-width: 2px !important;
    background: transparent !important;

    .overlay {
      display: none;
    }
  }

  &[has-value="1"] {
    background: transparent !important;
  }

  &[has-content="1"] {
    background: transparent !important;
  }

  &[has-background="0"] {
    background: transparent !important;
  }

  &[is-border-none="1"] {
    border: none !important;
  }

  & > p {
    &:last-child {
      margin: 12px 7px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  & > div {
    &:last-child {
      width: 100% !important;
      height: 100% !important;
      background: none;

      textarea.el-textarea__inner {
        padding: 0px 5px;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        background: transparent !important;
        resize: none !important;
        overflow: hidden;
        color: inherit;
      }

      .overlay {
        position: absolute;
        left: 5px !important;
        top: 0px !important;
        width: 100%;
        height: 100%;
        z-index: 5;
        padding: 0px !important;
      }

      .el-select {
        width: 100% !important;
        height: 100% !important;

        .el-input {
          width: 100% !important;
          height: 100% !important;
          font: inherit;

          .el-input__inner {
            width: 100% !important;
            height: 100% !important;
          }
        }

        .el-input__suffix-inner {
          i {
            line-height: 100% !important;
          }
        }

        .el-tag {
          height: 100%;
          font: inherit;
        }
      }

      .el-date-editor {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        font: inherit;
        background: none;

        .el-input__inner {
          width: 100%;
          height: 100%;
          font: inherit;
          border: none;
        }

        .el-input__prefix {
          position: relative;

          i {
            line-height: 100%;
            display: inline !important;
          }
        }
      }

      input[type="checkbox"] {
        width: 100%;
        height: 100%;
      }

      &.yes-or-no {
        // padding-left: 5px;
        .el-radio {
          font: inherit !important;

          .el-radio__label {
            font: inherit !important;
          }

          &:first-child {
            margin-right: 10px !important;
          }

          &:last-child {
          }
        }
      }

      .primary-field {
        text-area.el-textarea__inner {
          background: transparent !important;
        }
      }

      &.image-field {
        // padding: 3px 12px;
        color: inherit;
        position: absolute;
        left: 0px !important;
        top: 0px !important;
        padding: 0px !important;
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  & > img {
    &.my-signature {
      background: transparent !important;
      width: 100%;
      height: 100%;
    }

    &.my-initial {
      background: transparent !important;
      width: 100%;
      height: 100%;
    }
  }
}

.pdf-page-preview {
  width: 100%;
  text-align: center;
  border-right: 1px solid #ccc;

  .add-page-bottom {
    background: transparent;
    border: none;
  }

  .add-page-top {
    border: none;
    position: relative;
    background: transparent;
    top: 30px;
    // width: 40px;
    // height: 40px;
  }

  .rotate-buttons {
    position: relative;
    top: 60px;

    .el-button {
      visibility: hidden;
      // visibility: visible;
    }
  }

  .canvas-holder {
    position: relative;
    top: -25px;

    &:hover {
      .rotate-buttons {
        .el-button {
          visibility: visible;
        }

        // background-color: honeydew;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}

.settings-btn {
  cursor: pointer;
  color: #4c4b4e;

  span {
    margin-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.settigns-font {
  margin-left: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.document-editor-container {
  position: relative;
  height: calc(100vh - 62px);
  overflow: hidden;

  .el-scrollbar {
    height: calc(100vh - 145px) !important;
  }
}

.configure-document {
  width: 100%;

  .el-collapse-item__header {
    &.is-active {
      background-color: #ffffff;
    }
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
  }

  padding-top: 0 !important;
  background-color: #f8f9fa;

  .handle {
    width: 8px;
    height: 8px;
    background: inherit;
    border: 1px solid inherit;
  }

  .each-element.my-active-class {
    border: 1px dashed #f36371 !important;
  }

  .each-element.hidden-class {
    display: none;
  }

  .form-title {
    .el-input {
      position: relative;

      input {
        padding: 0;
        line-height: 1;
        background-color: rgba(255, 255, 255, 0.35);
        border: none;
        font-size: 1.5em;
        height: 60px !important;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: 0;
        color: #222222;
        box-shadow: none;
        align-items: stretch;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 20px;
        width: 10px;
        //background-image: url(/img/hover-dots.ecfb2fa2.svg);
        background-size: 10px 20px;
        background-repeat: no-repeat;
        align-items: stretch;
        background-position: center;
        left: 0px;
        opacity: 0;
        z-index: 10;
        transition: 0.3s all ease-in-out;
        -webkit-transition: 0.3s all ease-in-out;
      }
    }
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}

.fields-dialog {
  .el-select {
    width: 100%;
  }

  .el-dialog__body {
    padding-right: 5px !important;
    scrollbar-width: none;

    .el-scrollbar__bar {
      &.is-horizontal {
        height: 0px !important;
      }
    }

    .el-form {
      padding: 0px 13px 5px 0px !important;
    }
  }
}

.ScrollStyle {
  max-height: 150px;
  overflow-y: scroll;
}

.form-fields-holder {
  .date-picker-item {
    &.smaller-than {
      .el-input__prefix {
        display: none;
      }

      .el-input__inner {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .pdf-side-bar {
    display: none !important;
  }
}

-wrapper .top-bar-list {
  float: right;

  .list-item {
    float: left;
    padding: 2px;
    margin: 2px;
  }
}

.el-tooltips {
  padding: 9px 10px !important;
  cursor: pointer;
}

.testLine {
  margin-top: 10px;
}
</style>
<style lang="scss" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;

  @media (max-width: 400px) {
    // flex-direction: column;
  }

  .left-side {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;

      @media (max-width: 400px) {
        display: none;
      }
    }

    h4 {
      margin-left: 5px;
      line-height: 36px;
      height: 38px;

      @media (max-width: 400px) {
        max-width: 100px;
      }

      @media (max-width: 350px) {
        max-width: 80px;
      }
    }

    .action-wrapper-type-two {
      button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .right-side {
    @media (max-width: 400px) {
      // margin-bottom: 10px;
    }

    .top-bar-list {
      display: flex;
      justify-content: end;

      .list-item {
        margin-left: 5px;

        .el-tooltip {
          padding: 9px 10px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.main-content {
  display: flex;
  position: fixed;
  width: 100vw;

  .left-side-bar {
    margin-top: 60px;
    width: 15%;

    //z-index: 200 !important;
    .entityFieldSets {
      max-height: calc(100vh - 142px);
      background: white;
    }

    .scrollable-container {
      max-height: calc(100vh - 172px);

      .el-scrollbar {
        height: calc(100vh - 154px);

        @media (min-width: 992px) {
          height: calc(100vh - 170px);
        }
      }
    }
  }

  .base-parent {
    width: 100%;

    @media (min-width: 992px) {
      // width: calc(100% - 185px);
    }
  }
}

.template-dashboard-view {
  &.mobile {
    &.is-close-RS {
      .left-side-bar {
        display: none;
      }
    }
  }
  .dashboard-view.mobile .left-side-bar {
    position: absolute;
    z-index: 11000;
  }
  &.edit-new-template-view {
    // height: calc(100vh - 100px) !important;
  }
}

.dragArea-field-element {
  & > button {
    align-items: center;
  }
}
</style>
<style lang="scss">
.create-new-template-popup {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .last-row-in-form {
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .template-sharing-button {
        margin-top: 20px;

        @media (max-width: 600px) {
        }
      }

      .action-wrapper {
        display: flex;
        align-items: end;

        @media (max-width: 600px) {
          margin-top: 20px;
          justify-content: end;
        }
      }
    }

    .title-section {
      @media (max-width: 500px) {
        display: flex !important;
        flex-direction: column;
      }
    }
  }
}

.add-an-element-popup {
  .el-dialog {
    //width: 800px;
    width: 80%;
    margin-top: 10vh !important;
    max-height: 80vh !important;
    height: auto !important;

    .el-dialog__body {
      max-height: 60vh !important;
      padding: 1em 1.5em !important;
      overflow-y: scroll !important;
      overflow-x: hidden;

      // If you have a global styling for the webkit scrollbar that
      // interferes with the specific styling you want for the el-dialog__body,
      // you might need to be more specific in your CSS selectors to ensure that the styles
      // are applied only to the desired elements.

      /* First Reset styles for the webkit scrollbar in el-dialog__body */
      &::-webkit-scrollbar {
        width: initial;
        height: initial;
      }

      &::-webkit-scrollbar-thumb {
        width: initial;
        height: initial;
        border-radius: initial;
        background-clip: initial;
        border: initial;
      }

      /* Add the specific styles for the webkit scrollbar in el-dialog__body */
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 0px;
        border-radius: 10px;
        background-clip: padding-box;
        border: 10px solid rgba(0, 0, 0, 0.28);
      }
    }

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 350px;
      margin-top: 40px !important;
    }

    @media (max-width: 360px) {
      width: 310px;
      margin-top: 40px !important;
    }

    .el-select {
      &.filledby {
        min-width: 118px;
      }
    }

    .form-group {
      .el-form-item {
        .el-form-item__label {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.select-an-icon-popup {
  .el-dialog {
    width: 800px;
    margin-top: 10vh !important;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .el-select {
      &.filledby {
        min-width: 118px;
      }
    }

    .form-group {
      .el-form-item {
        .el-form-item__label {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.template-settings-drawer {
  .el-drawer {
    min-width: 230px;
    max-width: 900px;

    .el-drawer__header {
      margin-bottom: 0px;
      padding-bottom: 0px;

      span[role="heading"] {
        font-size: 22px;
        padding-top: 10px;
      }
    }

    .el-drawer__body {
      padding: 15px;

      .el-form {
        .el-form-item {
          .el-form-item__label {
            padding-right: 0px;
            width: 90px !important;
          }

          .el-input-number {
            width: 100px;
          }

          &.save-settings-button {
            margin-top: 30px;

            .el-form-item__content {
              text-align: left;
              margin-left: 0px !important;

              button {
                // padding-left: 8px;
                // padding-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.add-an-element-popup {
  .el-row {
    & > .el-col {
      .primary-details-title {
        // margin-left: 120px;
        @media (max-width: 600px) {
          margin-left: 0px !important;
        }
      }
    }
  }

  .field-required {
    & > .el-row {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;

        & > .el-col {
          width: 100%;

          &:first-child {
            margin-bottom: 5px;
          }

          .primary-details-title {
            margin-left: 0px;
          }
        }
      }
    }
  }

  div[aria-label="Add Global Variable"] {
    &.is-fullscreen {
      height: inherit !important;
    }
  }

  .formula-dialog {
    .form-left {
      min-height: 0px !important;
    }

    .form-right {
      .operaters {
        .el-button-group {
          button {
            margin-bottom: 5px;

            @media (max-width: 600px) {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.child_entity_scroll {
  scrollbar-width: thin;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard-view {
  background: #f8f9fa;
  min-height: 100vh;

  // min-height: calc(100vh - 68px);
  .canvas {
    .main-dashboard-view {
      margin-top: 55px;
      // padding-top: 30px;
      // padding-left: 100px;
      // padding-right: 30px;
    }
  }

  .offCanvas {
    .main-dashboard-view {
      margin-top: 41px;
      // padding-top: 30px;
      // padding-left: 100px;
      // padding-right: 30px;
    }
  }

  .preview-template-view {
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          display: flex;
          flex-direction: column;

          & > div {
            position: relative !important;
            left: 20px !important;
            top: 0px !important;
            // margin-top: 30px;
            margin-bottom: 10px;
            min-height: 50px;
            width: calc(100% - 40px) !important;

            .esignature-execute {
              left: 0px !important;
            }
          }
        }

        & > div {
          margin-top: 20px;

          & > .form-group {
            height: 100%;

            & > div {
              &:first-child {
                height: 21px;
              }

              &:nth-child(2) {
                height: 100%;

                .el-input {
                  // height: 100% !important;
                  input {
                    // height: calc(100% - 21px);
                    // height: calc(100% - 0px);
                    // min-height: 30px;
                    height: 100%;
                  }
                }
              }
            }

            & > .is-align-middle {
              & > div {
                height: 100%;

                .el-textarea {
                  height: 100% !important;

                  textarea {
                    height: calc(100% + 21px);
                    min-height: 0px !important;
                  }
                }

                .el-select {
                  // height: 100% !important;
                  .el-input {
                    height: 100%;

                    input {
                      height: 100%;
                      // min-height: 0px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.el-switch.is-checked .el-switch__core {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
</style>
<style>
.icon-sty {
  display: inline-grid;
  grid-template-columns: auto auto;

  /* padding:80px; */
}
.el-button--primary.is-plain {
  color: var(--primary-color);
  background: var(--primary-contrast-color);
  border-color: var(--primary-color);
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
}
.popUp-content {
  margin: 20px;
}

.advanced-fields-container {
  max-height: 65vh !important;
  overflow-y: auto !important;
  scrollbar-width: thin !important;
}
</style>
<style scoped>
el-button__primary:hover {
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.content-fields-container {
  max-height: 50vh !important;
  overflow-y: auto !important;
  scrollbar-width: thin !important;
}
.icons-component {
  margin: 0px;
}
</style>
